<template>
  <div class="back-link" @click="router.back()">
    <font-awesome-icon icon="fa-solid fa-arrow-left"/>
    {{text}}</div>
</template>

<script setup>
import {defineProps} from "vue";
import {useRouter} from 'vue-router';

const router = useRouter();

defineProps(['text']);

</script>

<style scoped>
.back-link {
  margin-bottom: 20px;
  color: var(--text-gray-color);
  font-size: 14px;
  cursor: pointer;
}
</style>