<template>
  <footer>
    <div class="footer-outer">
      <div class="footer-inner">
        <div class="footer-col">
          <router-link :to="{name: 'Homepage', params: {city: currentCityCode}}" class="footer-logo">
            <img src="/images/logos/logo_white_orange.svg" alt="footer-logo">
          </router-link>
        </div>
        <div class="menu-list">
          <div class="menu-list-title">{{ deviceWidth > 768 ? 'Услуги' : 'Меню' }}</div>
          <router-link class="menu-list-item"
          v-for="(service, i) in servicesItems" :key="i" :to="{name: service.link, hash: service.hash, params: {city: currentCityCode}}">
            <div class="menu-list-item__icon">
              <font-awesome-icon :icon="`fa-solid fa-${service.icon}`" style="color: var(--text-gray-color)"/>
            </div>
            {{service.label}}
          </router-link>
          <template v-if="deviceWidth <=768">
            <router-link v-for="(menu, i) in menuItems" :key="i" :to="{name: menu.link, params: {city: currentCityCode}}" class="menu-list-item">
              <div class="menu-list-item__icon">
                <font-awesome-icon :icon="`fa-solid fa-${menu.icon}`"
                                   style="color: var(--text-gray-color)"/>
              </div>
              {{ menu.label }}
            </router-link>
          </template>
        </div>
        <div v-if="deviceWidth > 768" class="menu-list">
          <div class="menu-list-title">Прочее</div>
          <router-link v-for="(menu, i) in menuItems" :key="i" :to="{name: menu.link, params: {city: currentCityCode}}" class="menu-list-item">
            <div class="menu-list-item__icon">
              <font-awesome-icon :icon="`fa-solid fa-${menu.icon}`"
                                 style="color: var(--text-gray-color)"/>
            </div>
            {{ menu.label }}
          </router-link>
        </div>
        <div class="menu-list" v-if="terminals.find(t=>t.city === cities.find(c=>c.code === currentCityCode)?.name)?.telephones
|| terminals.find(t=>t.city === cities.find(c=>c.code === currentCityCode)?.name)?.email">
          <div class="menu-list-title">Контакты</div>
          <a href="tel: 8 (800) 300 49 11" class="menu-list-item">
            8 (800) 300 49 11 <span>- бесплатно по России</span>
          </a>
          <a v-for="tel in
            terminals.find(t=>t.city === cities.find(c=>c.code === currentCityCode)?.name && +t.main === 1)?.telephones.length ?
terminals.find(t=>t.city === cities.find(c=>c.code === currentCityCode)?.name && +t.main === 1)?.telephones :
terminals.find(t=>t.city === cities.find(c=>c.code === currentCityCode)?.name)?.telephones"
             :key="tel"
              :href="`tel: ${tel}`"
             class="menu-list-item">
            {{ tel }} <span> - {{ cities.find(c=>c.code === currentCityCode)?.name }}</span>
          </a>
          <a href="mailto: info@cts-group.ru" class="menu-list-item">
            {{ terminals.find(t => t.city === cities.find(c => c.code === currentCityCode)?.name && +t.main === 1)?.email
          || terminals.find(t => t.city === cities.find(c => c.code === currentCityCode)?.name)?.email }}
          </a>
        </div>
      </div>
      <div class="subfooter">
        <div class="copyright">© {{ year }} CTS-GROUP</div>
<!--        <a href="/privacy" class="privacy-link">Политика конфиденциальности</a>-->
      </div>
    </div>
  </footer>
</template>

<script setup>
import {useStore} from "vuex";
import {ref, onMounted, onUnmounted, computed} from "vue";
import {useRoute, useRouter} from 'vue-router'

const route = useRoute();
const router = useRouter();
const store = useStore();
let now = new Date();
let year = now.getFullYear();
let city = ref('msk');
const deviceWidth = ref(window.innerWidth);
const servicesItems = [
  {
    label: 'Забор груза',
    icon: 'truck',
    link: 'OrderFormPage'
  },
  {
    label: 'Калькулятор',
    icon: 'calculator',
    link: 'Homepage',
    hash: '#calculator'
  },
  {
    label: 'Отследить груз',
    icon: 'magnifying-glass',
    link: 'TrackOrderPage'
  }
];
const menuItems = [
  {label: 'Личный кабинет', icon: 'user', link: 'ProfilePage'},
  {label: 'Тарифы', icon: 'ruble-sign', link: 'RatesPage'},
  {label: 'Филиалы', icon: 'compass', link: 'AddressesPage'},
  {label: 'Документы', icon: 'folder-open', link: 'DocumentsPage'},
];
const handleResize = () => {
  deviceWidth.value = window.innerWidth
}

const currentCityCode = computed(()=>{
  return store.state.currentCityCode;
})

const cities = computed(()=>{
  return store.state.cities;
})

const terminals = computed(()=>{
  return store.state.terminals;
})

onMounted(async () => {
  window.addEventListener('resize', handleResize)
  await router.isReady();
  if (route.query.city) {
    city.value = route.query.city;
  }
});

onUnmounted(()=>{
  window.removeEventListener('resize', handleResize)
})
</script>

<style scoped lang="scss">
footer {
  background: var(--black-bg-color);
  padding: 80px 0 40px;

  * {
    color: #FFFFFF;
  }

  .footer-outer {
    margin: 0 auto;
    max-width: var(--desktop-maxwidth);
    padding: 0 var(--hor-padding);
  }

  .footer-inner {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    margin-bottom: 80px;

    .footer-col {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .footer-logo {
      display: block;
      flex-grow: 1;
      margin-bottom: auto;
    }

    .menu-list {
      &-title {
        margin-bottom: 24px;
        font-size: 24px;
      }

      &-item {
        display: block;
        margin-bottom: 16px;
        color: #FFFFFF;
        font-size: 16px;
        line-height: 144%;
        &__icon {
          display: inline-block;
          text-align: center;
          width: 30px;
        }

        span {
          color: var(--text-gray-color);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .subfooter {
    display: flex;

    .copyright {
      color: var(--text-gray-color);
      flex-grow: 1;
    }

    .privacy-link {
      color: var(--text-gray-color);
      text-decoration: underline;
    }
  }
}

@media screen and (max-width: 768px) {
  footer {
    padding: 60px 0 32px;
    .footer-inner {
      grid-template-columns: repeat(3, 1fr);
      .menu-list {
        &-title {
          margin-bottom: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  footer {
    padding: 40px 0;

    .footer-inner {
      grid-template-columns: 1fr;
      grid-gap: 40px;
      .menu-list {
        &-item {
          margin-bottom: 8px;
        }
      }
    }
    .subfooter {
      flex-direction: column;
      row-gap: 16px;
    }
  }
}
</style>