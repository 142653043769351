<template>
<el-dialog v-model="dialogVisible" :title="title" width="320px" :align-center="true">
  <div class="confirm-dialog-actions">
    <el-button @click="close">{{declineButton || 'Нет'}}</el-button>
    <el-button @click="emits('confirm', id)" type="primary">{{confirmButton || 'Да'}}</el-button>
  </div>
</el-dialog>
</template>

<script setup>
import {ref, defineExpose, defineProps, defineEmits} from 'vue';
defineProps(['title', 'confirmButton', 'declineButton']);
const emits = defineEmits(['confirm', 'decline'])

const dialogVisible = ref(false)
const id = ref(null);
const open = (_id) => {
  id.value = null;
  dialogVisible.value = true;
  id.value = _id;
}

const close = () =>{
  emits('decline');
  dialogVisible.value = false;
}


defineExpose({
  open,
  close,
})
</script>

<style scoped lang="scss">
.confirm-dialog-actions {
  display: grid;
  margin-top: 32px;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 12px;
}
</style>