<template>
  <div class="page profile-page">
    <h1 class="page-title">Грузы</h1>
    <el-alert style="margin-bottom: 20px" v-if="user?.currentCompany?.verified==2"
              :title="`Запрос на получение прав на ${store.state.user.currentCompany.fs} «${store.state.user.currentCompany.name}» отправлен на модерацию. Ожидайте...`"
              type="warning" show-icon>
      <el-button @click="reload()" text>Обновить</el-button>
    </el-alert>
    <el-alert style="margin-bottom: 20px" v-if="user?.currentCompany?.verified==3"
              :title="`Нет прав на ${store.state.user.currentCompany.fs} «${store.state.user.currentCompany.name}»`"
              type="error" show-icon/>
    <div class="search-container">
      <el-input v-model="searchValue" placeholder="Поиск по таблице"/>
      <el-checkbox label="Только свои грузы" @change="getItems"/>
    </div>
    <EasyDataTable
        :headers="headers"
        :items="items"
        empty-message="Нет грузов"
        :loading="loading"
        theme-color="var(--el-color-primary)"
        alternating
        rows-of-page-separator-message="из"
        rows-per-page-message="строк на странице:"
        :search-field="['number', 'date_sdachi', 'sender', 'city_from', 'receiver', 'city_to', 'naim', 'invoice_number','sum']"
        :search-value="searchValue"
    >
      <template #item-number="item">
        <router-link style="text-decoration: underline; color: #258eb0" :to="{name: 'ShipmentItemPage', params: {id:item.id, city: currentCityCode.value}}">{{item.number}}</router-link>
      </template>
      <template #item-date_sdachi="{ date_sdachi }">
        {{ moment(date_sdachi).locale('ru').format('DD.MM.YYYY') }}
      </template>
      <template #item-sum="{ sum }">
        <span style="white-space: nowrap">{{ (+sum || 0).toFixed(2) }} ₽</span>
      </template>
      <template #item-invoice_number="{ invoice_number }">
        <template v-if="invoice_number">{{ invoice_number }} от
          {{ moment(invoice_date).locale('ru').format('DD.MM.YYYY') }}
        </template>
      </template>
      <template #item-payed="{ payed }">
        <span v-if="+payed" style="color: #0bbd87">Да</span>
        <span v-else style="color: grey">Нет</span>
      </template>
      <template #item-files="{ files }">
        <el-dropdown v-if="files.length">
    <span class="el-dropdown-link" style="cursor: pointer;outline: none;display: flex;">
      Файлы
      <el-icon class="el-icon--right">
        <arrow-down/>
      </el-icon>
    </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item v-for="(file, key) in files" :key="key">
                <a :href="file.url">{{ file.name }}</a>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </template>
      <template #item-delivery_datetime="{ delivery_datetime }">
        <template v-if="+delivery_datetime">{{
            moment(delivery_datetime * 1000).locale('ru').format('DD.MM.YYYY')
          }}
        </template>
      </template>
    </EasyDataTable>
  </div>
</template>

<script setup>
import {computed, inject, onMounted, ref, watch} from 'vue';
import {useRouter} from "vue-router";
import {useStore} from "vuex";

const api = inject('api');
const moment = inject('moment');
const router = useRouter();
const store = useStore();
const items = ref([]);
const loading = ref(false);
const user = computed(() => {
  return store.getters.getUser;
})
const headers = [
  {text: 'Дата приема', value: 'date_sdachi', sortable: true},
  {text: 'Дата выдачи', value: 'delivery_datetime', sortable: true},
  {text: 'Накладная', value: 'number'},
  {text: 'Город отпр.', value: 'city_from'},
  {text: 'Отправитель', value: 'sender'},
  {text: 'Город пол.', value: 'city_to'},
  {text: 'Получатель', value: 'receiver'},
  {text: 'Мест', value: 'mest'},
  {text: 'Вес', value: 'ves'},
  {text: 'Объём', value: 'obem'},
  {text: 'Наименования', value: 'naim'},
  {text: 'Сумма', value: 'sum'},
  {text: 'Оплачен', value: 'payed'},
  {text: 'Счёт', value: 'invoice_number'},
  {text: 'Документы', value: 'files'},
  // {text: 'Заявка', value: ''},
];
const searchValue = ref('');
const getItems = (isOwn) => {
  loading.value = true;
  api.request('waybill/get', {own_only: isOwn ? 1 : 0}).then(data => {
    loading.value = false;
    items.value = data.response;
  }).catch(() => {
    loading.value = false;
  })
}

const reload = () => {
  window.location.reload();
}

const onRowClick = (id) => {
  router.push({name: 'ShipmentItemPage', params: {id, city: currentCityCode.value}})
}
onMounted(() => {
  getItems();
})

watch(() => store.state.user, () => {
  getItems();
})

const currentCityCode = computed(() => store.state.currentCityCode);

</script>

<style scoped lang="scss">
.search-container {
  margin-bottom: 16px;
  max-width: 400px;
}
</style>
