export default {
    methods: {
        thousandSeparator(sum) {
            return String(sum)
                .replace(/(\d)(?=(\d\d\d)+(\D|$))/g, '$1 '); // 1 000 000 000
        },
        getNoun(number, one, two, five) {
            let n = Math.abs(number);
            n %= 100;
            if (n >= 5 && n <= 20) {
                return five;
            }
            n %= 10;
            if (n === 1) {
                return one;
            }
            if (n >= 2 && n <= 4) {
                return two;
            }
            return five;
        },
        checkIntOrFloatInput(str, maxSize) {
            let result = str;
            result = str.replace(/[бю,&^]/ig, '.');
            if (result === '.') {
                result = '0.';
            }
            let regex = /^(?!0\d)(\d+|\d*\.\d{0,2})$/;
            if (!result.match(regex)) {
                result = result.slice(0, result.length - 1);
            }
            if (result > maxSize) {
                // result = maxSize;
            }
            return result;
        }
    }
}