<template>
  <div class="page not-found-page">
    <h1 class="title">Мы благодарны за&nbsp;ваши старания в&nbsp;поисках новых страниц</h1>
    <div class="subtitle">Но, к&nbsp;сожалению, этой страницы не&nbsp;существует</div>
    <div class="subtitle">Зато вы можете рассчитать стоимость доставки, либо оформить заказ!</div>
    <div class="actions">
      <router-link :to="{name: 'Homepage', hash: '#calculator'}" class="actions-link">
        <el-button class="actions-link-button">Рассчитать</el-button>
      </router-link>
      <router-link :to="{name: 'OrderFormPage'}" class="actions-link">
        <el-button type="primary" class="actions-link-button">Оформить заказ</el-button>
      </router-link>
    </div>
  </div>
</template>

<script setup>

import {useRoute, useRouter} from "vue-router";
import {onMounted} from "vue";
import {useStore} from "vuex";

const router = useRouter();
const route = useRoute();
const store = useStore();

onMounted(async ()=>{
  await router.isReady();
  store.commit('setCurrentCityCode', route.params.city);
})

</script>

<style scoped lang="scss">
.not-found-page {
  max-width: 660px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .title {
    max-width: 530px;
    margin-bottom: 32px;
    text-align: center;
  }
  .subtitle {
    margin-bottom: 16px;
    font-size: var(--headline-3);
    text-align: center;
  }
  .actions {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 12px;
    &-link {
      &-button {
        width: 100%;
      }
    }
  }
}
</style>