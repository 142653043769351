<template>
  <div class="page profile-page">
    <div class="page-title">Груз № {{ item.number }}</div>
    <div class="page-content">
      <div class="row cols-2">
        <el-card>
          <template #header>
            <div class="card-header">Отправитель</div>
          </template>
          <div class="info-item">
            <div class="info-item-label">Город отправления</div>
            <div class="info-item-value">{{ item.city_from }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">Отправитель</div>
            <div class="info-item-value">{{ item.sender }}</div>
          </div>
        </el-card>
        <el-card>
          <template #header>
            <div class="card-header">Получатель</div>
          </template>
          <div class="info-item">
            <div class="info-item-label">Город получения</div>
            <div class="info-item-value">{{ item.city_to }}</div>
          </div>
          <div class="info-item">
            <div class="info-item-label">Получатель</div>
            <div class="info-item-value">{{ item.receiver }}</div>
          </div>
        </el-card>
      </div>
      <div class="row cols-2">
        <el-card>
          <template #header>
            <div class="card-header">Услуги</div>
          </template>
          <div class="info-item" v-for="(priceItem, i) in item.price?.list" :key="i">
            <div class="info-item-label">{{ priceItem.title }}</div>
            <div class="info-item-value">{{ globalMixins.methods.thousandSeparator(priceItem.price.toFixed(2)) }} ₽
            </div>
          </div>
          <div class="info-item-result">
            <div class="info-item-label">Итого:</div>
            <div class="info-item-value">{{ globalMixins.methods.thousandSeparator(item.sum) }} ₽</div>
          </div>
          <div class="info-item-invoice" v-if="item.invoice_number">
            <div class="info-item-label">Счет:</div>
            <div class="info-item-value">{{ item.invoice_number }} от
              {{ moment(item.invoice_date).locale('ru').format('DD.MM.YYYY') }}
            </div>
          </div>
        </el-card>
        <el-card style="height: fit-content">
          <template #header>
            <div class="card-header">Информация о грузе</div>
          </template>
          <div class="info-item" v-for="(infoItem, key) in shipmentInfoItems" :key="key">
            <div class="info-item-label">{{ infoItem.label }}</div>
            <div class="info-item-value" v-if="item[key]">
              {{ globalMixins.methods.thousandSeparator(item[key]) || item.key }} {{ infoItem.measure }}
            </div>
          </div>
        </el-card>
      </div>
      <div class="row cols-2" v-if="item.files?.length">
        <el-card>
          <template #header>
            <div class="card-header">Файлы</div>
          </template>
          <div class="info-item" v-for="(file, key) in item.files" :key="key">
            <div class="info-item-label">{{ file.name }}</div>
            <div class="info-item-value">
              <a :href="file.url" target="_blank" class="el-button" style="width: 120px;height: 32px">Скачать</a>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script setup>
import {inject, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import globalMixins from "@/globalMixins";

const api = inject('api');
const route = useRoute();
const moment = inject('moment');
const item = ref({});

const shipmentInfoItems = {
  ves: {label: 'Вес', measure: 'кг'}, mest: {label: 'Мест'}, obem: {label: 'Объем', measure: 'м3'},
  prim: {label: 'Примечание'},
  osg: {label: 'Оценочная стоимость груза', measure: '₽'}
};
const getItem = () => {
  api.request(`waybill/get/${route.params.id}`).then(data => {
    item.value = data.response;
  })
}

onMounted(() => {
  getItem();
})
</script>

<style scoped lang="scss">
.page-content {
  .row:not(:last-child) {
    margin-bottom: 16px;
  }

  .card-header {
    font-weight: 500;
    font-size: 18px;
  }

  .info-item, .info-item-result, .info-item-invoice {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding: 12px 0;
    border-bottom: 1px solid var(--border-color);

    &-value {
      text-align: right;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  .info-item-result {
    background: var(--border-color);
    margin: 0 -20px;
    width: calc(100% + 40px);
    padding: 12px 20px;

    .info-item-value {
      font-weight: 500;
    }
  }

  .info-item-invoice {
    .info-item-value {
      text-align: left;
    }
  }
}

@media screen and (max-width: 575px) {
  .row > * {
    margin-bottom: 16px;
  }
}
</style>
