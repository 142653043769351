<template>
  <el-dialog v-model="dialogVisible" title="Выберите город">
    <ul class="city-list">
      <li v-for="(city, i) in store.state.cities.filter(c=>c.filial*1 === 1)"
          :key="i" class="city-list-item" :class="{'selected': currentCityCode === city.code}">
        <el-button text class="city-list-item__button"
                   @click="pickCity(city.code)">
          {{ city.name }}
        </el-button>
      </li>
    </ul>
  </el-dialog>
</template>

<script setup>
import {ref, defineExpose, inject, computed} from 'vue';
import {useStore} from 'vuex'
import {useRouter} from "vue-router";

const router = useRouter();
const store = useStore();
const dialogVisible = ref(false)
const api = inject('api');

const open = () => {
  dialogVisible.value = true;
}

const pickCity = city =>{
    router.replace({name: router.currentRoute.value.name, params: {city: city}, hash: router.currentRoute.value.hash,
    query: router.currentRoute.value.query});
    store.commit('setCurrentCityCode', city);
    dialogVisible.value = false;
}

const currentCityCode = computed(()=>{
  return store.state.currentCityCode;
})

defineExpose({
  open,
})
</script>

<style scoped lang="scss">
.city-list {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  &-item {
    display: flex;
    justify-content: center;
  }
  &-item.selected {
    .city-list-item__button {
      color: var(--el-color-primary);
    }
  }
}

@media screen and (max-width: 575px) {
  .city-list {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}
</style>