<template>
  <div class="subheader">
    <div class="subheader-inner">
      <el-button text class="subheader-city" @click="openPickCityDialog">
        <template #icon>
          <font-awesome-icon icon="fa-solid fa-location-dot"/>
        </template>
        {{ cities.find(c => c.code === currentCityCode)?.name }}
      </el-button>
      <a href="tel: 8 (800) 300 49 11" class="subheader-phone">
        <el-button text class="">
          <template #icon>
            <font-awesome-icon icon="fa-solid fa-phone"/>
          </template>
          <span>8 (800) 300 49 11 -&nbsp;</span>бесплатно по России
        </el-button>
      </a>
      <el-dropdown trigger="click" class="subheader-contacts"
                   v-if="terminals.find(t=>t.city === cities.find(c=>c.code === currentCityCode)?.name)?.telephones
|| terminals.find(t=>t.city === cities.find(c=>c.code === currentCityCode)?.name)?.email">
        <el-button text class="subheader-contacts-button">
          <template #icon>
            <font-awesome-icon icon="fa-solid fa-phone"/>
          </template>
          Контакты
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>
              <a href="mailto: info@cts-group.ru">
                <el-button text link class="contact-link-button">
                  <template #icon>
                    <font-awesome-icon icon="fa-solid fa-envelope"/>
                  </template>
                  {{
                    terminals.find(t => t.city === cities.find(c => c.code === currentCityCode)?.name && +t.main === 1)?.email
                    || terminals.find(t => t.city === cities.find(c => c.code === currentCityCode)?.name)?.email
                  }}
                </el-button>
              </a>
            </el-dropdown-item>
            <el-dropdown-item v-for="tel in
            terminals.find(t=>t.city === cities.find(c=>c.code === currentCityCode)?.name && +t.main === 1)?.telephones.length ?
terminals.find(t=>t.city === cities.find(c=>c.code === currentCityCode)?.name && +t.main === 1)?.telephones :
terminals.find(t=>t.city === cities.find(c=>c.code === currentCityCode)?.name)?.telephones"
                              :key="tel">
              <a :href="`tel: ${tel}`">
                <el-button text link class="contact-link-button">
                  <template #icon>
                    <font-awesome-icon icon="fa-solid fa-phone"/>
                  </template>
                  {{ tel }}
                </el-button>
              </a>
            </el-dropdown-item>
            <el-dropdown-item v-if="deviceWidth <= 575">
              <a href="tel: 8 (800) 300 49 11">
                <el-button text link class="contact-link-button">
                  <template #icon>
                    <font-awesome-icon icon="fa-solid fa-phone"/>
                  </template>
                  <span>8 (800) 300 49 11 -&nbsp;</span>бесплатно по России
                </el-button>
              </a>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup>
import {ref, defineEmits, onMounted, onUnmounted, computed} from "vue";
import {useStore} from 'vuex';
// import {useRoute, useRouter} from 'vue-router'
// const route = useRoute();
// const router = useRouter();
const store = useStore();
const emits = defineEmits(['openPickCityDialog'])
const deviceWidth = ref(window.innerWidth);
const handleResize = () => {
  deviceWidth.value = window.innerWidth
}
const openPickCityDialog = () => {
  emits('openPickCityDialog');
}

const currentCityCode = computed(() => {
  return store.state.currentCityCode;
})

const cities = computed(() => {
  return store.state.cities;
})

const terminals = computed(() => {
  return store.state.terminals;
})

onMounted(async () => {
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<style scoped lang="scss">
.subheader {
  width: 100%;
  background: #FFF;
  //border-bottom: 1px solid rgba(255, 255, 255, .4);
  //box-shadow: 2px 2px 16px rgba(255, 255, 255, .3);

  &-inner {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    max-width: var(--desktop-maxwidth);
    margin: 0 auto;
    padding: 0 var(--hor-padding);
  }

  &-city {
    padding-left: 0;
    justify-self: flex-start;
  }

  &-phone {
    display: block;
    justify-self: center;
  }

  &-contacts {
    justify-self: flex-end;

    &-button {
      padding-right: 0;
    }

    &-free-phone {
      display: none;
    }
  }
}

.contact-link-button {
  &:hover {
    opacity: 1 !important;
    color: var(--el-text-color-primary) !important;
  }
}

@media screen and (max-width: 768px) {
  .subheader {
    &-phone {
      span {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .subheader {
    &-inner {
      grid-template-columns: 1fr 1fr;
    }

    &-phone {
      display: none;
    }

    &-contacts {
      &-free-phone {
        display: block;
      }
    }
  }
}
</style>