<template>
    <div class="page documents-page">
      <div class="documents-page-title">Документы на транспортные услуги</div>
      <el-card>
        <ul class="documents-list">
          <li v-for="(doc, i) in docs" :key="i" class="documents-list-item">
            <a :href="`https://new.cts-group.ru/doc/${doc.link}`" target="_blank">{{ doc.title }}</a>
          </li>
        </ul>
      </el-card>
    </div>
</template>

<script setup>
const docs = [
  {title: 'Договор', link: 'Договор 11.02.19.doc'},
  {title: 'Заявка на перевозку груза', link: 'zayavka_cts.xls'},
  {title: 'Образец доверенности на получение груза', link: 'obrazets_doverennosti_na_polecheniye_gruza.xls'},
  {title: 'Письмо об изменении плательщика', link: 'pismo_ob_izmenenii_platelschika.doc'},
  {title: 'Письмо об изменении получателя', link: 'pismo_ob_izmenenii_poluchatelya.doc'},
  {title: 'Опись груза', link: 'Opis_gruza.doc'},
  {title: 'Образец доверенности для сдачи груза от физического лица', link: 'dov_otpr_fiz_2.doc'},
  {title: 'Образец доверенности для сдачи груза от юридического лица', link: 'dov_otpr_ur_2.xls'},
  {title: 'Образец генеральной доверенности для сдачи груза от юридического лица', link: 'dov_gen_general_2.doc'},
];
import {onMounted} from "vue";

onMounted(()=>{
  console.log('docs mounted')
})
</script>

<style scoped lang="scss">
.documents-page {
  &-title {
    margin-bottom: 40px;
    font-size: var(--headline-1);
  }
  .documents-list {
    counter-reset: listCounter;
    &-item {
      counter-increment: listCounter;
      margin-bottom: 16px;
      border-bottom: 1px solid var(--border-color);
      padding-bottom: 16px;
      &:before {
        display: inline-block;
        margin-right: 16px;
        content: counter(listCounter);
        color: var(--el-color-primary);
      }
      a {
        color: var(--el-text-color-primary);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .documents-page {
    padding: 160px var(--hor-padding);
    &-title {
      margin-bottom: 32px;
    }
  }
}
@media screen and (max-width: 575px) {
  .documents-page {
    &-title {
      margin-bottom: 24px;
    }
  }
}
</style>