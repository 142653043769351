<template>
  <el-drawer v-model="isVisible" title="Меню" direction="ltr">
    <div class="drawer-container">
      <el-menu class="side-menu">
        <el-menu-item v-for="(item, i) in menuItems" :key="i" @click="menuClick({link: item.link, hash: item.hash})">
          <font-awesome-icon :icon="`fa-solid fa-${item.icon}`" style="margin-right: 8px; color: var(--text-gray-color)"/>
          {{item.label}}
        </el-menu-item>
      </el-menu>
      <div class="drawer-actions">
        <router-link :to="{name: 'OrderFormPage'}">
          <el-button class="drawer-actions-button"
                     color="var(--el-text-color-primary)"
          @click="toggle">
            <template #icon>
              <font-awesome-icon icon="fa-solid fa-truck"/>
            </template>
            Забор груза</el-button>
        </router-link>
        <router-link :to="{name: 'TrackOrderPage'}">
          <el-button class="drawer-actions-button"
                     @click="toggle">
            <template #icon>
              <font-awesome-icon icon="fa-solid fa-magnifying-glass"/>
            </template>
            Отследить груз</el-button>
        </router-link>
      </div>
    </div>
  </el-drawer>
</template>

<script setup>
import {ref, defineExpose, computed} from 'vue'
import {useRouter} from "vue-router";
import {useStore} from "vuex";

const router = useRouter();
let isVisible = ref(false);
const store = useStore();

const menuItems = [
  {label: 'Личный кабинет', icon: 'user', link: 'ProfilePage'},
  {label: 'Калькулятор', icon: 'calculator',link: 'Homepage', hash: '#calculator'},
  {label: 'Тарифы', icon: 'ruble-sign', link: 'RatesPage'},
  {label: 'Филиалы и Контакты', icon: 'compass', link: 'AddressesPage'},
  {label: 'Документы', icon: 'folder-open', link: 'DocumentsPage'},
];
const toggle = () => {
  isVisible.value = !isVisible.value;
}

const menuClick = (params) => {
  router.push(  {name: params.link, params: {city: currentCityCode.value}, hash: params.hash});
  isVisible.value = false;
}

const currentCityCode = computed(()=>{
  return store.state.currentCityCode;
})
defineExpose({
  toggle,
})
</script>

<style scoped lang="scss">
.drawer-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .side-menu {
    border-right: none;
    margin-bottom: auto;
  }
  .drawer-actions {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    &-button {
      width: 100%;
    }
  }
}
</style>