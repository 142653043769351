<template>
  <div class="profile-page">
    <div class="profile-page-inner">
      <el-form :model="formData" label-position="top">
        <el-card class="user-card">
          <div class="row cols-3">
            <el-form-item label="Фамилия">
              <el-input v-model="formData.surname" placeholder="Фамилия"/>
            </el-form-item>
            <el-form-item label="Имя">
              <el-input v-model="formData.name" placeholder="Имя"/>
            </el-form-item>
            <el-form-item label="Отчество">
              <el-input v-model="formData.otch" placeholder="Отчество"/>
            </el-form-item>
          </div>
          <div class="row cols-3">
            <el-form-item label="Email">
              <el-input v-model="formData.email" placeholder="Email" disabled/>
            </el-form-item>
            <el-form-item label="Телефон">
              <el-input v-model="formData.phone" placeholder="+7 (   )" v-maska="store.state.phone_mask"/>
            </el-form-item>
            <el-form-item label="Доп. Email">
              <el-input v-model="formData.email_contact" placeholder="Дополнительный Email"/>
            </el-form-item>
          </div>
          <div class="row cols-3">
            <el-form-item label="Должность">
              <el-input v-model="formData.position" placeholder="Например, директор фирмы"/>
            </el-form-item>
            <el-form-item label="Дата рождения">
              <el-date-picker v-model="formData.birthday" placeholder="ДД.ММ.ГГГГ" value-format="YYYY-MM-DD"
                              format="DD.MM.YYYY"
                              class="input-width-100" clearable/>
            </el-form-item>
            <el-form-item label="Пол">
              <el-select v-model="formData.gender" class="input-width-100">
                <el-option v-for="(gender, i) in store.state.genders" :key="i" :value="i" :label="gender"/>
              </el-select>
            </el-form-item>
          </div>
          <div class="form-actions">
            <el-button type="primary" @click="updateUser" :loading="loadingUserUpdate">Сохранить</el-button>
          </div>
        </el-card>
<!--        <el-card header="Информация о компании">
          <div class="row cols-3">
            <el-form-item label="ИНН">
              <el-input v-model="formData.company.inn" placeholder=""/>
            </el-form-item>
            <el-form-item label="КПП">
              <el-input v-model="formData.company.kpp" placeholder=""/>
            </el-form-item>
            <el-form-item label="Название компании">
              <el-input v-model="formData.company.name" placeholder=""/>
            </el-form-item>
          </div>
          <div class="row cols-2">
            <el-form-item label="Телефон для уведомлений">
              <el-input v-model="formData.company.phone" placeholder=""/>
            </el-form-item>
            <el-form-item label="Email для уведомлений">
              <el-input v-model="formData.company.email" placeholder=""/>
            </el-form-item>
          </div>
          <div class="row cols-2">
            <el-form-item>
              <el-switch v-model="formData.company.sms_notifications" :active-value="1" :inactive-value="0"
                         active-text="СМС-Уведомления"/>
            </el-form-item>
            <el-form-item>
              <el-switch v-model="formData.company.email_notifications" active-text="Email-Уведомления"
                         :active-value="1" :inactive-value="0"/>
            </el-form-item>
          </div>
          <div class="form-actions">
            <el-button type="primary" @click="updateUser">Сохранить</el-button>
          </div>
        </el-card>-->
      </el-form>
    </div>
  </div>
</template>

<script setup>
import {computed, inject, onMounted, reactive, watch, ref} from "vue";

import {useStore} from "vuex";
import {useRouter} from "vue-router/dist/vue-router";
const router = useRouter();
const api = inject('api');
const store = useStore();
const formData = reactive({
  name: '',
  surname: '',
  otch: '',
  phone: '',
  email: '',
  position: '',
  gender: 0,
  birthday: '',
  email_contact: '',
  company: {
    name: '',
    inn: '',
    kpp: '',
    phone: '',
    email: '',
    sms_notifications: false,
    email_notifications: false,
  }
});
const loadingUserUpdate = ref(false);
const user = computed(()=>{
  return store.getters.getUser;
})


const updateUser = ()=>{
  loadingUserUpdate.value = true;
  api.request('user/update', formData).then(data=>{
    loadingUserUpdate.value = false;
    router.push({name: 'ShipmentsPage'});
    store.commit("setUser",{...user.value, ...data.response});
  }).catch(()=>{
    loadingUserUpdate.value = false;
  })
}

watch(()=>user,  ()=>{
  Object.assign(formData, user);
})

onMounted(()=>{
  Object.assign(formData, store.state.user);
})
</script>

<style scoped lang="scss">
.profile-page {
  &-inner {
    max-width: 840px;
    .user-card {
      margin-bottom: 40px;
    }
  }
}
</style>
