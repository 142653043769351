<template>
  <div class="page">
    <h1 class="page-title">{{ priceType === 'intercity' ? 'Междугородние тарифы' : 'Тарифы на экспедирование' }}</h1>
    <el-tabs v-model="priceType" @tab-change="onTabChange">
      <el-tab-pane label="Междугородние" name="intercity">
        <el-form :model="formDataInterCity" label-position="top" class="filter-form">
          <div class="row cols-3 small-gaps">
            <el-form-item label="Город отправления">
              <el-select v-model="formDataInterCity.city_from" placeholder="Выберите из списка" class="input-width-100"
                         @change="getRates">
                <el-option v-for="city in cities" :key="city.id" :value="city.id"
                           :label="city.name"/>
              </el-select>
            </el-form-item>
            <el-form-item label="Город получения">
              <el-select v-model="formDataInterCity.city_to" placeholder="Выберите из списка" class="input-width-100"
                         @change="getRates">
                <el-option v-for="city in [{name: 'Все'}, ...cities]" :key="city.id" :value="city.id"
                           :label="city.name"/>
              </el-select>
            </el-form-item>
            <el-form-item label="По">
              <el-radio-group v-model="formDataInterCity.byVolume" class="row cols-2 small-gaps"
                              @change="getRates">
                <el-radio border :label="1">объему</el-radio>
                <el-radio border :label="0">весу</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </el-form>
        <a v-if="commentsInterCity.comment_file" :href="commentsInterCity.comment_file" target="_blank">
          <el-button>Скачать прайс-лист</el-button>
        </a>
        <div v-if="loadingIntercity" class="loading">
          Идет загрузка...
        </div>
        <template v-else>
          <el-table :data="interCityTableData.filter(row=> cities.filter(city=>city.id === row.city_pol*1).length)"
                    stripe>
            <el-table-column label="Город получения" sortable min-width="140">
              <template #default="scope">
                {{ cities.filter(city => city.id === scope.row.city_pol * 1)[0]?.name || '---' }}
              </template>
            </el-table-column>
            <el-table-column label="В пути (дней)" prop="time_v_puti" sortable/>
            <el-table-column label="Цена (мин)" prop="min" sortable/>
            <el-table-column v-for="size in sizesInterCityNames" :key="size"
                             :prop="`${size}`"
                             :label="`${ sizesInterCity[`${size}_min`]} - ${ sizesInterCity[`${size}_max`]}`" sortable/>

          </el-table>
          <div v-if="commentsInterCity.comment_html" v-html="commentsInterCity.comment_html" style="margin-top: 24px;"/>
        </template>
      </el-tab-pane>
      <el-tab-pane label="Экспедирование" name="expedition">
        <el-form :model="formDataExpedition" label-position="top" class="filter-form">
          <div class="row cols-3">
            <el-form-item label="Город">
              <el-select v-model="formDataExpedition.city" placeholder="Выберите из списка" class="input-width-100"
                         @change="getExpeditionRates">
                <el-option v-for="city in citiesWithTariff" :key="city.id" :value="city.id"
                           :label="city.name"/>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <a v-if="commentsExpedition.comment_file" :href="commentsExpedition.comment_file" target="_blank"
           style="display: block; margin-bottom: 16px; width: fit-content;">
          <el-button>Скачать прайс-лист</el-button>
        </a>
        <div v-if="loadingExpedition" class="loading">
          Идет загрузка...
        </div>
        <template v-else>
          <el-table :data="expeditionTableData" stripe>
            <el-table-column label="Вес (кг)">
              <template #default="scope">
                {{ `от ${scope.row.ves_min} до ${scope.row.ves_max}` }}
              </template>
            </el-table-column>
            <el-table-column label="Объем (м3)">
              <template #default="scope">
                {{ `до ${scope.row.obiem_max}` }}
              </template>
            </el-table-column>
            <el-table-column label="Габариты (м) max">
              <el-table-column label="Д" prop="dlina"/>
              <el-table-column label="Ш" prop="shirina"/>
              <el-table-column label="В" prop="visota"/>
            </el-table-column>
            <el-table-column prop="euro_pallet" label="Евро Паллет"/>
            <el-table-column prop="cena_tarif"
                             :label="formDataExpedition.city === '1' ? 'Тариф (в пределах МКАД)' : 'Тариф (в пределах города)'"/>
            <el-table-column prop="cena_km"
                             :label="formDataExpedition.city === '1' ? 'руб/км за МКАД' : 'руб/км за городом'"/>
            <el-table-column v-if="formDataExpedition.city === '1'" prop="zaezd_v_ttk" label="Пропуск в ТТК"/>
            <el-table-column v-if="formDataExpedition.city === '1'" prop="sadovoe" label="Въезд в Садовое кольцо"/>
            <el-table-column prop="dop_tochka" label="Доп. точка погрузки"/>
            <el-table-column prop="norma_pogruzki" label="Норма погрузки"/>
            <el-table-column prop="dop_chas_pogruzki" label="Сверх нормы погрузки (руб/час)"/>
          </el-table>
          <div v-if="commentsExpedition.comment_html" style="margin-top: 24px;"
               v-html="commentsExpedition.comment_html"/>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script setup>

import {ref, inject, onMounted, reactive, computed, watch} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";

const router = useRouter();
const route = useRoute();
const store = useStore();
const api = inject('api');
const priceType = ref('intercity');
const formDataInterCity = reactive({city_from: null, city_to: null, byVolume: 0});
const interCityTableData = ref([]);
const sizesInterCity = reactive({});
const sizesInterCityNames = ref([]);
const commentsInterCity = reactive({comment_file: null, comment_html: null})
const loadingIntercity = ref(false);

const formDataExpedition = reactive({city: null});
const expeditionTableData = ref([]);
const commentsExpedition = reactive({comment_file: null, comment_html: null});
const loadingExpedition = ref(false);

const queryParams = reactive({});
const onTabChange = (tab) => {
  priceType.value = tab;
  if (tab === 'expedition') {
    if (!formDataExpedition.city) {
      formDataExpedition.city = cities.value.find(c=>c.code === currentCityCode.value).id;
    }
    getExpeditionRates();
    router.push({path: 'rates', query: {expedition: 1, ...formDataExpedition}})
  } else {
    if (!formDataInterCity.city_from) {
      formDataInterCity.city_from = cities.value.find(c=>c.code === currentCityCode.value).id;
    }
    getRates();
    router.push({path: 'rates', query: formDataInterCity})
  }
}
const getRates = () => {
  loadingIntercity.value = true;
  api.request('order/price', formDataInterCity).then(data => {
    loadingIntercity.value = false;
    interCityTableData.value = data.response.rows;
    sizesInterCityNames.value = Object.keys(data.response.sizes)
        .map(name => {
          return name.split('_')[0];
        })
    sizesInterCityNames.value = sizesInterCityNames.value.filter((val, i) => val !== sizesInterCityNames.value[i - 1] && val !== 'id');
    Object.assign(sizesInterCity, data.response.sizes);
    commentsInterCity.comment_file = data.comment_file;
    commentsInterCity.comment_html = data.comment_html;
  }).catch(data => {
    loadingIntercity.value = false;
  })
}

const getExpeditionRates = () => {
  loadingExpedition.value = true;
  api.request('order/priceExp', formDataExpedition).then(data => {
    loadingExpedition.value = false;
    expeditionTableData.value = data.response;
    commentsExpedition.comment_html = data.comment_html;
    commentsExpedition.comment_file = data.comment_file;
  }).catch(() => {
    loadingExpedition.value = false;
  })
}

const currentCityCode = computed(() => {
  return store.state.currentCityCode;
})

const cities = computed(() => {
  return store.state.cities;
})

const citiesWithTariff = computed(() => {
  return store.state.citiesWithTariff;
})

onMounted(async () => {
  await router.isReady();
  Object.assign(queryParams, route.query);
  let queryParamsKey = Object.keys(queryParams);
  queryParamsKey.forEach((key) => {
    queryParams[key] = +queryParams[key];
  })
  if (!queryParams.city_from && !queryParams.expedition)
    formDataInterCity.city_from = +cities.value.find(c => c.code === currentCityCode.value)?.id || 1;
  if (!queryParams.city && queryParams.expedition === 1)
    formDataExpedition.city = +cities.value.find(c => c.code === currentCityCode.value)?.id || 1;
  if (queryParams.expedition === 1) {
    Object.assign(formDataExpedition, queryParams)
    getExpeditionRates();
    priceType.value = 'expedition';
  } else {
    Object.assign(formDataInterCity, queryParams)
    priceType.value = 'intercity';
    getRates();
  }
})

watch(() => formDataInterCity, () => {
  router.push({query: formDataInterCity})
}, {deep: true});

watch(() => formDataExpedition, () => {
  router.push({query: {...formDataExpedition, expedition: 1}})
}, {deep: true});

watch([() => cities.value, () => currentCityCode.value], () => {
  if (!queryParams.city_from && !queryParams.expedition)
    formDataInterCity.city_from = +cities.value.find(c => c.code === currentCityCode.value)?.id || 1;
  if (!queryParams.city && queryParams.expedition === 1)
    formDataExpedition.city = +cities.value.find(c => c.code === currentCityCode.value)?.id || 1;
  if (priceType.value === 'intercity') getRates();
  else getExpeditionRates();
})
</script>

<style scoped lang="scss">
.filter-form {
  max-width: 580px;
}
</style>
