<template>
  <div class="page profile-page">
    <h1 class="page-title">Сотрудники</h1>
    <EasyDataTable
        :headers="headers"
        :items="workers"
        @click-row="onRowClick"
        empty-message="Нет cотрудников"
        :loading="loading"
        theme-color="var(--el-color-primary)"
        alternating
        rows-of-page-separator-message="из"
        rows-per-page-message="строк на странице:"
        :search-field="['name', 'email', 'position']"
        :search-value="searchValue"
    >
      <template #item-name="obj">
        {{ obj.user?.surname }} {{ obj.user?.name || '' }} {{ obj.user?.otch || '' }}
      </template>
      <template #item-status="obj">
        <span>{{workerStatuses[+obj.verified]}}</span>
      </template>
      <template #item-admin="obj">
        <el-switch v-model="obj.admin" :disabled="+obj.verified !== 1" @change="val=>updateWorker({admin: val, user: obj.user.id})"  active-value="1" inactive-value="0"/>
      </template>
      <template #item-actions="obj">
        <el-button v-if="+obj.verified >= 2"
            size="small" style="height: 32px; border: 1px solid #679f6a; margin-right: 8px" @click="updateWorker({verified: 1, user: obj.user.id})">
          {{ +obj.verified === 2 ? 'Подтвердить' : 'Разблокировать' }}</el-button>
        <el-button v-if="[1, 2].includes(+obj.verified)"
            size="small" style="height: 32px;border: 1px solid #d78990" @click="updateWorker({verified: 3, user: obj.user.id, admin: 0})">
          {{ +obj.verified === 2 ? 'Отклонить' : 'Заблокировать' }}</el-button>
      </template>
    </EasyDataTable>
  </div>
</template>

<script setup>
import {computed, inject, onMounted, ref} from "vue";
import {useStore} from "vuex";
import ConfirmDialog from "@/dialogs/ConfirmDialog";

const api = inject('api');
const store = useStore();
const workers = ref([]);
const headers = [
  {value: 'name', text: 'ФИО'},
  {value: 'user.position', text: 'Должность'},
  {value: 'user.email', text: 'Email'},
  {value: 'status', text: 'Статус'},
  {value: 'admin', text: 'Админ'},
  {value: 'actions'}
];
const loading = ref(false);
const searchValue = ref('');

const getWorkers = () => {
  loading.value = true;
  api.request(`company/coworkers/${user.value.currentCompany?.id}`).then(data => {
    loading.value = false;
    workers.value = data.response;
  }).catch(() => {
    loading.value = false;
  })
}

const updateWorker = (obj) => {
  api.request(`company/updateCoworker/${user.value.currentCompany?.id}`, obj).then(() => {
    getWorkers();
  })
}

const onRowClick = (obj) => {

}

onMounted(() => {
  getWorkers();
})


const workerStatuses = computed(()=>store.state.workerStatuses);

const user = computed(() => store.state.user);
</script>

<style scoped>
</style>