<template>
  <div v-if="userGetLoading" class="page user-loading">
    Идет загрузка...
  </div>
  <div v-else-if="store.state.user?.id" class="page profile-page-container">
    <router-view/>
  </div>
  <div v-else class="page auth-container" :key="authType">
    <el-card v-if="authType === 'login'" key="login" class="auth-card">
      <div class="auth-card-title">Авторизация</div>
      <el-form :model="loginData" class="login-container">
        <el-form-item :error="emailError">
          <el-input v-model="loginData.email" placeholder="Email" autocomplete="email" type="email" clearable
                    @clear="loginData.token = '';" @input="loginData.token = ''"/>
        </el-form-item>
        <el-form-item v-if="loginData.token" :error="codeError">
          <div class="code-input-container">
            <el-input v-model="loginData.code" placeholder="Одноразовый код" autocomplete="one-time-code"
                      v-maska="'####'"
                      class="input-width-100"/>
          </div>
        </el-form-item>
        <div class="card-actions">
          <el-button v-if="!loginData.token" @click="getCode" :loading="getCodeLoading" type="primary"
                     class="login-button">
            Далее
          </el-button>
          <el-button v-else @click="checkCode" :loading="loginLoading" type="primary" class="login-button">Войти
          </el-button>
          <router-link :to="{name: 'ProfilePage', query: {authType: 'registry'}}" class="register-card-login-button">
            <el-button @click="authType = 'registry'" text>Нет аккаунта? <span>Зарегистрироваться</span></el-button>
          </router-link>
        </div>
      </el-form>
    </el-card>
    <el-card v-else key="registry" class="auth-card">
      <div class="auth-card-title">Регистрация</div>
      <el-form ref="formRegister" :model="formDataRegister" :rules="formDataRegisterRules" label-position="top"
               :scroll-to-error="true" style="width: 100%">
        <div class="row cols-2 small-gaps">
          <el-form-item label="Email" required prop="email" :error="emailError">
            <el-input v-model="formDataRegister.email" placeholder="Email" type="email" autocomplete="email"
                      class="input-width-100" @input="emailError = '';formDataRegister.token = ''" clearable
                      @clear="formDataRegister.token = ''"/>
          </el-form-item>
          <el-form-item label="Телефон" required prop="phone">
            <el-input v-model="formDataRegister.phone" placeholder="+7 (" autocomplete="tel" class="input-width-100"
                      v-maska="store.state.phone_mask"/>
          </el-form-item>
        </div>
        <div class="row cols-3 small-gaps">
          <el-form-item required prop="name" label="Имя">
            <el-input v-model="formDataRegister.name" placeholder="Имя"/>
          </el-form-item>
          <el-form-item required prop="surname" label="Фамилия">
            <el-input v-model="formDataRegister.surname" placeholder="Фамилия"/>
          </el-form-item>
          <el-form-item label="Отчество">
            <el-input v-model="formDataRegister.otch" placeholder="Отчество (если есть)"/>
          </el-form-item>
        </div>
        <el-divider/>
        <el-radio-group v-model="formDataRegister.clientType" class="client-type-buttons">
          <el-radio border size="large" :label="0" class="form-radio-button">Юр. лицо</el-radio>
          <el-radio border size="large" :label="1" class="form-radio-button">Физ. лицо</el-radio>
        </el-radio-group>
        <template v-if="formDataRegister.clientType === 0">

          <div class="row cols-2 small-gaps">
            <el-form-item label="ИНН Компании" required prop="inn">
              <el-input @input="[10,12].includes(formDataRegister.inn.trim().length)?findByInn():''"
                        v-model="formDataRegister.inn" placeholder="ИНН" v-maska="store.state.mask_inn">
                <template #append>
                  <el-button @click="findByInn()">
                    <template #icon>
                      <font-awesome-icon icon="fa-solid fa-magnifying-glass"/>
                    </template>
                    Искать
                  </el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="КПП Компании">
              <el-input v-model="formDataRegister.kpp" placeholder="КПП"/>
            </el-form-item>
          </div>
          <div class="row cols-2 small-gaps">
            <el-form-item label="Название компании">
              <el-input v-model="formDataRegister.company" placeholder="Например, ООО 'Ромашка'"/>
            </el-form-item>
            <el-form-item label="Должность" required prop="position">
              <el-input v-model="formDataRegister.position" placeholder="Укажите должность"/>
            </el-form-item>
          </div>
        </template>
        <el-form-item v-if="formDataRegister.token" :error="codeError">
          <div class="code-input-container">
            <el-input v-model="loginData.code" placeholder="Одноразовый код" autocomplete="one-time-code"
                      class="input-width-100" v-maska="'####'"/>
          </div>
        </el-form-item>
        <div class="card-actions">
          <el-button v-if="!formDataRegister.token" @click="register" :loading="getCodeLoading"
                     class="register-button" type="primary">Далее
          </el-button>
          <el-button v-else @click="checkCode" type="primary" class="register-button"
                     :loading="loadingRegister">Зарегистрироваться
          </el-button>
          <router-link :to="{name: 'ProfilePage', query: {authType: 'login'}}">
            <el-button @click="authType = 'login'" text class="register-card-login-button">Есть аккаунт?
              <span>Войти</span></el-button>
          </router-link>
        </div>
      </el-form>
    </el-card>
  </div>
</template>

<script setup>
import ProfileHeader from "@/pages/profile/components/ProfileHeader";

import {useRouter, useRoute} from "vue-router";
import {ref, onMounted, reactive, inject, computed} from "vue";

import {useStore} from 'vuex';

const store = useStore();
const router = useRouter();
const route = useRoute();
const api = inject('api');

const authType = ref('login');
const formDataRegister = reactive({
  email: '',
  name: '',
  surname: '',
  otch: '',
  inn: '',
  kpp: '',
  company: '',
  position: '',
  phone: '',
  clientType: 0,
  register: 1,
  token: ''
});

const formDataRegisterRules = {
  email: [
    {required: true, message: 'Укажите email', trigger: 'blur', type: 'email'},
    {required: true, message: 'Укажите email', trigger: 'change', type: 'email'},
  ],
  name: [
    {required: true, message: 'Укажите имя', trigger: 'blur'},
    {required: true, message: 'Укажите имя', trigger: 'change'},
  ],
  surname: [
    {required: true, message: 'Укажите фамилию', trigger: 'blur'},
    {required: true, message: 'Укажите фамилию', trigger: 'change'},
  ],
  phone: [
    {required: true, message: 'Укажите телефон', trigger: 'blur'},
    {required: true, message: 'Укажите телефон', trigger: 'change'},
  ],
  inn: [
    {required: true, message: 'Укажите ИНН', trigger: 'blur'},
    {required: true, message: 'Укажите ИНН', trigger: 'change'},
  ],
  position: [
    {required: true, message: 'Укажите должность', trigger: 'blur'},
    {required: true, message: 'Укажите должность', trigger: 'change'},
  ]
}

const formRegister = ref(null);
const loadingRegister = ref(false);

const loginData = reactive({email: '', code: '', token: ''});
const emailError = ref('');
const codeError = ref('');
const getCodeLoading = ref(false);
const loginLoading = ref(false);
const register = () => {
  formRegister.value.validate(isValid => {
    if (isValid) {
      loadingRegister.value = true;
      api.request('auth/login', formDataRegister).then((data) => {
        loadingRegister.value = false;
        formDataRegister.token = data.response;
      }).catch(data => {
        emailError.value = data.error?.text || data.error;
        loadingRegister.value = false;
      })
    }
  })
}

const getCode = () => {
  getCodeLoading.value = true;
  api.request('auth/login', {email: loginData.email}).then((data) => {
    getCodeLoading.value = false;
    loginData.token = data.response;
  }).catch(data => {
    getCodeLoading.value = false;
    emailError.value = data.error?.text || data.error;
  })
}
const checkCode = () => {
  if (authType.value === 'registry' && !formDataRegister.token) {
    register();
    return;
  }
  loginLoading.value = true;
  api.request('auth/checkCode', {
    code: loginData.code,
    token: authType.value === 'registry' ? formDataRegister.token : loginData.token
  }).then(data => {
    loginLoading.value = false;
    router.push({name: 'ShipmentsPage', params: {city: currentCityCode.value}});
    if (data.response) {
      if (!data.response.currentCompany) {
        let ids = Object.keys(data.response.companies);
        if (ids.length) {
          data.response.currentCompany = data.response.companies[ids[0]];
          localStorage.setItem('currentCompanyId', ids[0]);
        }
      }
      store.commit('setUser', data.response);
    }
    Object.assign(loginData, {email: '', code: ''});
    Object.assign(formDataRegister, {
      email: '',
      name: '',
      surname: '',
      otch: '',
      inn: '',
      kpp: '',
      company: '',
      position: '',
      phone: '',
      clientType: 0,
      register: 1
    })
  }).catch(data => {
    loginLoading.value = false;
    codeError.value = data.error?.text || data.error;
  })
}

const findByInn = () => {
  api.request(`user/findByINN?inn=${formDataRegister.inn}`).then(data => {
    formDataRegister.kpp = data.response.kpp;
    formDataRegister.company = data.response.name;
  });
}

const currentCityCode = computed(() => {
  return store.state.currentCityCode;
})

const userGetLoading = computed(() => store.state.userGetLoading);

onMounted(async () => {
  await router.isReady();
  authType.value = route.query.authType || 'login';
})
</script>

<style scoped lang="scss">
.profile-page-container {
  padding-top: 0;
}

.auth-card {
  max-width: 640px;
  margin: 0 auto;

  &-title {
    font-size: var(--headline-3);
    font-weight: 500;
    margin-bottom: 24px;
    text-align: center;
  }

  .client-type-buttons {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-bottom: 16px;
  }

  .card-actions {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .register-card-login-button span {
    display: inline-block;
    margin-left: 8px;
    color: var(--el-color-primary);
  }

  .login-container {
    max-width: 320px;
    margin: 0 auto;
  }

  .code-input-container {
    display: flex;
    gap: 8px;
    width: 100%;
  }

  .login-button {
    width: 100%;
  }

  .login-card-registry-button {
    margin: 0 auto;
  }
}

.register-button {
  width: 100%;
  max-width: 280px;
}
</style>
