<template>
  <el-dialog v-model="dialogVisible" title="Новая компания" width="480px" :align-center="true">
    <el-form :model="formData" ref="form" label-position="top" :rules="rules">
      <div class="row cols-2 small-gaps inn-kpp-inputs">
        <el-form-item prop="inn" required label="ИНН">
          <el-input v-model="formData.inn" placeholder="10 или 12 цифр"
                    v-maska="store.state.mask_inn">
            <template #append>
              <el-button @click="findByInn()">
                <template #icon>
                  <font-awesome-icon icon="fa-solid fa-magnifying-glass"/>
                </template>
                Искать
              </el-button>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="kpp" label="КПП">
          <el-input v-model="formData.kpp" placeholder="9 цифр"
                    :disabled="formData.inn?.length > 10"
                    v-maska="'#########'"/>
        </el-form-item>
      </div>
      <el-form-item prop="name" required label="Название компании">
        <el-input v-model="formData.name" placeholder="Например, ООО 'Ромашка'" disabled/>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="formData.access" label="Запросить права"/>
      </el-form-item>
      <div class="form-actions">
        <el-button type="primary" @click="sendForm" :loading="loading">Сохранить</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script setup>
import {defineExpose, inject, reactive, ref, defineEmits, computed} from "vue";
import {useStore} from "vuex";

const emits = defineEmits(['on-send-form']);

const store = useStore();
const api = inject('api');
const dialogVisible = ref(false)
const form = ref();
const formData = reactive({inn: '', kpp: '', name: '', verified: 0, id: '', access: false});
const rules = reactive({
  inn: [
    {trigger: 'blur', required: true, message: 'Укажите ИНН'},
    {trigger: 'change', required: true, message: 'Укажите ИНН'},
  ],
  name: [
    {trigger: 'blur', required: true, message: 'Укажите название'},
    {trigger: 'change', required: true, message: 'Укажите название'},
  ],
})
const loading = ref(false);
const open = () => {
  Object.assign(formData, {inn: '', kpp: '', name: '', verified: 0, id: ''});
  dialogVisible.value = true;
  setTimeout(()=>{
    form.value.resetFields();
  })
}

const close = () => {
  dialogVisible.value = false;
}

const sendForm = () => {
  form.value.validate(isValid => {
    if (isValid) {
      loading.value = true;
      formData.verified = formData.access ? 2 : 0;
      api.request( formData.id ? `company/update/${formData.id}` : 'company/create', formData).then(data => {
        loading.value = false;
        emits('on-send-form');
        dialogVisible.value = false;
      }).catch(data => {
        loading.value = false;
      })
    }
  })
}

const findByInn = () => {
  api.request(`user/findByINN?inn=${formData.inn}`).then(data => {
    formData.kpp = data.response?.kpp;
    formData.name = data.response?.name;
  });
}

const user = computed(()=>store.state.user);

defineExpose({
  open,
  close,
})
</script>

<style scoped lang="scss">
.inn-kpp-inputs {
  grid-template-columns: 2fr 1fr;
}

@media screen and (max-width: 575px) {
  .inn-kpp-inputs {
    grid-template-columns: 1fr;
    grid-gap: 0 !important;
  }
}
</style>