<template>
  <header>
    <TheSubheader @open-pick-city-dialog="openPickCityDialog"/>
    <div class="header-inner">
      <router-link :to="{name: 'Homepage', params: {city: currentCityCode}}">
        <img src="/images/logos/logo_white_orange.svg" alt="" class="desktop-logo">
        <img src="/images/logos/logo_white_orange_small.svg" alt="" class="mobile-logo">
      </router-link>
      <div class="open-drawer-button-container">
        <el-button text @click="openMenu" class="text-color-white open-drawer-button">
          <template #icon>
            <font-awesome-icon icon="fa-solid fa-bars"/>
          </template>
          Меню
        </el-button>
      </div>
      <!--      <div class="header-contacts">
              <a class="header-contacts-row">
                <font-awesome-icon icon="fa-solid fa-envelope"/>
                mail@mail.ru
              </a>
              <a class="header-contacts-row">
                <font-awesome-icon icon="fa-solid fa-phone"/>
                8 (495) 276-0-376
              </a>
              <a class="header-contacts-row">
                <font-awesome-icon icon="fa-solid fa-phone"/>
                8 (800) 30-04-911 бесплатно по России
              </a>
            </div>-->
      <div class="header-buttons">
        <router-link :to="{name: 'OrderFormPage', params: {city: currentCityCode}}" class="order-sbor-button">
          <el-button class="text-color-white el-button--white-border">
            <template #icon>
              <font-awesome-icon icon="fa-solid fa-truck"/>
            </template>
            Забор груза
          </el-button>
        </router-link>
        <router-link :to="{name: 'TrackOrderPage', params: {city: currentCityCode}}" class="order-sbor-button">
          <el-button class="text-color-white el-button--white-border">
            <template #icon>
              <font-awesome-icon icon="fa-solid fa-magnifying-glass"/>
            </template>
            Отследить груз
          </el-button>
        </router-link>
        <el-dropdown trigger="contextmenu" popper-class="mobile-fullscreen-width" ref="authDropdown">
          <el-button @click="onAccountButtonClick" class="text-color-white el-button--white-border" style="width: 48px">
            <template #icon>
              <font-awesome-icon icon="fa-solid fa-user"/>
            </template>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <div class="login-menu">
                <div class="login-menu-title">Вход в Личный кабинет</div>
                <el-form :model="loginData">
                  <el-form-item :error="emailError">
                    <el-input v-model="loginData.email" placeholder="Email" autocomplete="email" type="email" clearable
                              @clear="secretKey = ''"
                              @input="emailError = ''; secretKey = ''"/>
                  </el-form-item>
                  <el-form-item v-if="secretKey" :error="codeError">
                    <el-input v-model="loginData.code" placeholder="Одноразовый код" autocomplete="one-time-code"
                              @input="codeError = ''" v-maska="'####'"/>
                  </el-form-item>
                  <el-button v-if="!secretKey" @click="getCode"
                             :loading="getCodeLoading"
                             type="primary" class="login-button">Далее
                  </el-button>
                  <el-button v-else @click="login" :loading="loginLoading"
                             type="primary" class="login-button">Войти
                  </el-button>
                  <router-link
                      :to="{name: 'ProfilePage', query: {authType: 'registry'}, params: {city: currentCityCode}}"
                      class="login-menu-subactions">
                    <el-button text>Регистрация</el-button>
                  </router-link>
                </el-form>
              </div>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </header>
</template>

<script setup>
import {ref, defineEmits, inject, reactive, computed} from "vue";
import TheSubheader from "@/components/TheSubheader";
import {useRouter} from "vue-router";
import {useStore} from "vuex";

const router = useRouter();
const api = inject('api');
const emits = defineEmits(['openMenu', 'openPickCityDialog'])
const store = useStore();
const loginData = reactive({email: '', code: ''});
const emailError = ref('');
const codeError = ref('');
const secretKey = ref(null);
const authDropdown = ref();

const getCodeLoading = ref(false);
const loginLoading = ref(false);

const openMenu = () => {
  emits('openMenu');
}

const openPickCityDialog = () => {
  emits('openPickCityDialog');
}

const onAccountButtonClick = () => {
  if (store.state.user?.id) router.push({name: 'ShipmentsPage', params: {city: currentCityCode.value}});
  else authDropdown.value.handleOpen();
}
const getCode = () => {
  getCodeLoading.value = true;
  api.request('auth/login', {email: loginData.email}).then((data) => {
    getCodeLoading.value = false;
    secretKey.value = data.response;
  }).catch(data => {
    getCodeLoading.value = false;
    emailError.value = data.error?.text || data.error;
  })
}
const login = () => {
  loginLoading.value = true;
  api.request('auth/checkCode', {code: loginData.code, token: secretKey.value}).then(data => {
    loginLoading.value = false;
    router.push({name: 'ShipmentsPage', params: {city: currentCityCode.value}});
    if (data.response) {
      if (!data.response.currentCompany) {
        let ids = Object.keys(data.response.companies);
        if (ids.length) {
          data.response.currentCompany = data.response.companies[ids[0]];
          localStorage.setItem('currentCompanyId', ids[0]);
        }
      }
      store.commit('setUser', data.response);
    }
    authDropdown.value.handleClose();
    // router.push({name: 'ProfilePage', params: {city: currentCityCode.value}});
  }).catch(data => {
    loginLoading.value = false;
    codeError.value = data.error?.text || data.error;
  })
}

const currentCityCode = computed(() => {
  return store.state.currentCityCode;
})

</script>

<style scoped lang="scss">
header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
  background-image: linear-gradient(90deg, rgb(0 0 0) 0%, rgb(25 25 25) 50%, rgb(0 0 0) 100%);

  .header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    width: 100%;
    max-width: var(--desktop-maxwidth);
    margin: 0 auto;
    padding: 8px var(--hor-padding);

    .mobile-logo {
      display: none;
    }

    .open-drawer-button-container {
      flex-grow: 1;
    }

    .open-drawer-button {
      width: fit-content;
    }

    .header-buttons {
      display: flex;
      column-gap: 12px;
    }

    .order-sbor-button {
      display: flex;
      justify-content: flex-end;
    }
  }

  .header-contacts {
    &-row {
      display: block;
      margin-bottom: 6px;
      color: #FFF;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.login-menu {
  padding: 16px 24px;
  min-width: 320px;

  &-title {
    margin-bottom: 16px;
    font-size: var(--headline-3);
  }

  .code-input-container {
    display: flex;
    gap: 8px;
  }

  .login-button {
    width: 100%;
  }

  &-subactions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 575px) {
  header {
    .header-inner {
      grid-template-columns: 1fr 1fr;

      .desktop-logo {
        display: none;
      }

      .mobile-logo {
        display: block;
        height: 30px;
      }

      .open-drawer-button-container {
        flex-grow: 0;
      }

      .open-drawer-button {
        padding-right: 0;
        justify-content: flex-end;
      }

      .header-buttons {
        display: none;
      }
    }
  }
  .login-menu {
    max-width: 100%;
    width: 100%;
  }
}
</style>
