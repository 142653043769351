import {createRouter, createWebHistory} from 'vue-router'

import TheContainer from "@/components/TheContainer";
import HomePage from '@/pages/HomePage'
import SborPage from '@/pages/SborFormPage'
import NotFoundPage from "@/pages/NotFoundPage";
import AddressesPage from "@/pages/AddressesPage";
import DocumentsPage from "@/pages/documents/DocumentsPage";
import ProfileView from "@/pages/profile/ProfileView";
import ProfilePage from "@/pages/profile/ProfilePage";
import TrackOrderPage from "@/pages/TrackOrderPage";
import RatesPage from "@/pages/rates/RatesPage"
import DocumentsView from "@/pages/documents/DocumentsView";
import OrdersPage from "@/pages/profile/OrdersPage";
import ShipmentsPage from "@/pages/profile/shipments/ShipmentsPage";
import ShipmentItemPage from "@/pages/profile/shipments/ShipmentItemPage";
import ShipmentItemView from "@/pages/profile/shipments/ShipmentItemView";
import WorkersPage from "@/pages/profile/workers/WorkersPage";

const routes = [
    {
        path: '/',
        redirect: '/moskva',
        children: [
            {
                path: ':city',
                component: TheContainer,
                children: [
                    {
                        path: '',
                        name: 'Homepage',
                        component: HomePage,
                    },
                    {
                        path: 'track',
                        name: 'TrackOrderPage',
                        component: TrackOrderPage
                    },
                    {
                        path: 'sbor',
                        name: 'OrderFormPage',
                        component: SborPage,
                        children: [
                            {
                                path: 'update/:id/:token?',
                                name: 'OrderFormPageUpdate',
                                component: SborPage
                            },
                            {
                                path: ':id',
                                name: 'OrderPage',
                                component: SborPage
                            },
                        ]
                    },
                    {
                        path: 'addresses',
                        name: 'AddressesPage',
                        component: AddressesPage
                    },
                    {
                        path: 'documents',
                        component: DocumentsView,
                        children: [
                            {
                                path: '',
                                name: 'DocumentsPage',
                                component: DocumentsPage,
                            }
                        ]
                    },
                    {
                        path: 'profile',
                        component: ProfileView,
                        children: [
                            {
                                path: '',
                                name: 'ProfilePage',
                                component: ProfilePage
                            },
                            {
                                path: 'orders',
                                name: 'OrdersPage',
                                component: OrdersPage
                            },
                            {
                                path: 'shipments',
                                children: [
                                    {
                                        path: '',
                                        name: 'ShipmentsPage',
                                        component: ShipmentsPage,
                                    },
                                    {
                                        path: ':id',
                                        component: ShipmentItemView,
                                        children: [
                                            {
                                                path: '',
                                                name: 'ShipmentItemPage',
                                                component: ShipmentItemPage
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                path: 'workers',
                                name: 'WorkersPage',
                                component: WorkersPage
                            },
                            {
                                path: 'balance',
                                name: 'ProfileBalancePage'
                            }
                        ]
                    },
                    {
                        path: 'rates',
                        name: 'RatesPage',
                        component: RatesPage
                    },
                ]
            },

        ]
    },
    {
        path: '/:city/:pathMatch(.*)',
        component: NotFoundPage,
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth'
            }
        } else {
            return {
                top: 0
            }
        }
    }
})

router.beforeEach((to, from, next) => {
    const location = to.params.city;
    const routes = ['track', 'sbor', 'addresses', 'documents', 'profile', 'rates'];
    if (routes.includes(location)) {
        next({ path: `/moskva${to.path}`});
    } else next();
});

export default router;