<template>
  <div class="app-container">
    <TheDrawer ref="drawer"/>
    <TheHeader @open-menu="openMenu" @open-pick-city-dialog="openPickCityDialog"/>
    <ProfileHeader v-if="(route.path.split('/').some(v=>v==='profile') || route.query.from_profile) && user.id"/>
    <TheContainer/>
    <TheFooter/>
    <PickCityDialog ref="pick_city_dialog"/>
    <InfoDialog ref="info_dialog"/>
  </div>
</template>

<script setup>
import {computed, inject, onMounted, ref} from "vue";
import {useStore} from "vuex";
import TheHeader from "@/components/TheHeader";
import TheFooter from "@/components/TheFooter";
import TheDrawer from "@/components/TheDrawer";
import TheContainer from "@/components/TheContainer";
import PickCityDialog from "@/dialogs/PickCityDialog";
import '@/assets/fonts/Roboto/font.css';
import {useRoute, useRouter} from "vue-router";
import InfoDialog from "@/dialogs/InfoDialog";
import ProfileHeader from "@/pages/profile/components/ProfileHeader";

const router = useRouter();
const route = useRoute();
const store = useStore();
const api = inject('api');
const drawer = ref();
const pick_city_dialog = ref();
const info_dialog = ref();

const openMenu = () => {
  drawer.value.toggle();
}

const openPickCityDialog = () => {
  pick_city_dialog.value.open();
}

const getCity = async () => {
  store.commit('setUserGetLoading', true);
  api.request(`user/get`).then((data) => {
    store.commit('setUserGetLoading', false);
    store.commit('setCities', data.response.cities);
    store.commit('setCitiesWithTariff', data.response.citiesWithTariff);
    if (!data.response.cities.find(c=>c.code === currentCityCode.value)) {
      router.replace(`/moskva${route.path}`);
    }
    store.commit('setTerminals', data.response.terminals);
    store.commit('setWorkerStatuses', data.response.coworkerStatuses);
    if (data.response.user) {
      if (!data.response.user.currentCompany) {
        let ids = Object.keys(data.response.user.companies);
        if (ids.length) {
          data.response.user.currentCompany = data.response.user.companies[ids[0]];
          localStorage.setItem('currentCompanyId', ids[0]);
        }
      }
      store.commit('setUser', data.response.user);
    }
  }).catch(()=>{
    store.commit('setUserGetLoading', false);
  });
}

const openInfoDialog = (title) => {
  info_dialog.value.open(title);
}

const user = computed(()=>store.state.user);

const currentCityCode = computed(()=>store.state.currentCityCode);

window.open_info_dialog = openInfoDialog;

onMounted(async () => {
  await router.isReady();
  store.commit('setCurrentCityCode', route.params.city);
  await getCity();
})
</script>
<style>

</style>
