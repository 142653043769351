<template>
  <div class="sbor-form-page" :class="{'sbor-form-page__profile': route.query.from_profile}">
    <h1 class="sbor-form-page-title">{{ formData.id ? `Заявка № ${formData.code}` : 'Заказать забор груза' }}</h1>
    <el-card v-if="formData.receiver_terminal && formData.file_pdf" style="margin-bottom: 16px">
      <div class="edit-hint">
        <a :href="formData.file_pdf" target="_blank">
          <font-awesome-icon icon="fa-solid fa-download" style="margin-right: 8px"/>
          Скачать заявку на забор груза № {{ formData.code }}
        </a>
      </div>
    </el-card>
    <el-card v-if="isViewing && user.id" style="margin-bottom: 16px">
      <div class="edit-hint">
        <font-awesome-icon icon="fa-solid fa-circle-info" style="margin-right: 8px"/>
        После обработки заявки менеджером редактирование невозможно
      </div>
    </el-card>
    <div class="sbor-form-page-container">
      <el-card class="sbor-form-content" body-class="relative-card-body">
        <div v-if="isViewing" class="sbor-form-content-overlay"/>
        <el-form :model="formData" :rules="rules" class="sbor-form"
                 label-position="top" :scroll-to-error="true" ref="form"
                 autocomplete="new-password"
                 @change="calculate">
          <template v-if="!user?.id">
            <div class="row cols-2 small-gaps">
              <el-form-item label="Email" :error="emailError" prop="email">
                <el-input v-model="authData.email" placeholder="Email" @input="emailError = ''"
                          @change="getCode"/>
              </el-form-item>
              <el-form-item v-if="authData.token" label="Код из письма" :error="codeError">
                <div class="code-input-container">
                  <el-input v-model="authData.code" placeholder="_ _ _ _" autocomplete="one-time-code"
                            @input="codeError = ''"/>
                </div>
              </el-form-item>
            </div>
            <div class="auth-hint">Укажите Email для дальнейшего доступа к заявке и отслеживанию груза</div>
          </template>
          <div class="row cols-2">
            <el-form-item label="Контактный телефон" required prop="phone">
              <el-input v-model="formData.phone" placeholder="+7 ("
                        v-maska="store.state.phone_mask"/>
            </el-form-item>
          </div>
          <div class="row cols-2">
            <el-form-item label="Предыдущие заявки">
              <el-select v-model="prevOrder" placeholder="Выберите из списка" @change="id=>getOrder(id, true)"
                         no-data-text="Для выбора из прошлых заявок, сначала введите email выше"
                         filterable
                         class="input-width-100">
                <el-option v-for="order in orders" :key="order.id" :value="order.id"
                           :label="`${order.naim}. ${[cities.find(c=>c.id===order.city_from)?.name, order.sender_title || order.sender_name].join(', ')} -
                    ${[cities.find(c=>c.id===order.city_to)?.name, order.receiver_title || order.receiver_name].join(', ')}`">
                  <div class="past-order-item">
                    <div class="past-order-item-title">
                      {{ order.naim }}.
                      {{
                        [cities.find(c => c.id === order.city_from)?.name, order.sender_title || order.sender_name].join(', ')
                      }}
                      -
                      {{
                        [cities.find(c => c.id === order.city_to)?.name, order.receiver_title || order.receiver_name].join(', ')
                      }}
                    </div>
                    <div class="past-order-item-subtitle">{{ moment(order.date).locale('ru').format('D MMM YYYYг.') }}
                    </div>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="Дата забора" required prop="date">
            <div class="inline-calendar">
              <div class="inline-calendar-container">
                <div class="dates">
                  <div class="date"
                       :class="{
                      'selected': formData.date === day
                     }"
                       v-for="day in dates.closest" :key="day"
                       @click="selectDate(day)">
                    <div class="date__day">{{ moment(day).format('DD.MM') }}</div>
                    <div class="date__weekday">
                      {{ moment(day).locale('ru').format('dd') }}
                    </div>
                  </div>
                  <div class="date selected"
                       :style="`opacity: ${formData.date && !dates.closest.includes(formData.date) ? 1 : 0}`">
                    <div class="date__day">{{ moment(formData.date).format('DD.MM') }}</div>
                    <div class="date__weekday" :class="{'weekend': [0, 6].includes(moment(formData.date).day())}">
                      {{ moment(formData.date).locale('ru').format('dd') }}
                    </div>
                  </div>
                  <div class="other-date-picker">
                    <div @click="openDatePicker" class="date">
                      <font-awesome-icon icon="fa-solid fa-calendar-days" style="font-size: 20px"/>
                    </div>
                  </div>
                  <el-date-picker v-model="formData.date" style="opacity: 0; width: 0; overflow: hidden"
                                  value-format="YYYY-MM-DD" format="DD.MM.YYYY" placeholder="ДД.ММ.ГГГГ"
                                  locale="ru"
                                  :picker-options="{firstDayOfWeek: 4}"
                                  ref="datepicker"
                                  :disabled-date="disabledDates"/>
                </div>
              </div>
            </div>
          </el-form-item>
          <div class="row cols-2 small-gaps">
            <el-form-item label="Время забора">
              <div class="time-range">
                <el-time-select v-model="formData.time_from" placeholder="__:__" start="07:00" step="00:30"
                                :end="formData.time_to || '23:30'"
                                v-maska="'##:##'"
                                class="input-width-100"/>
                -
                <el-time-select v-model="formData.time_to" placeholder="__:__" :start="formData.time_from || '07:00'"
                                step="00:30" end="23:30"
                                class="input-width-100"
                                v-maska="'##:##'"
                />
              </div>
            </el-form-item>
            <el-form-item label="Перерыв">
              <div class="time-range">
                <el-time-select v-model="formData.time_break_from" placeholder="__:__"
                                start="08:00" step="00:30" end="23:30"
                                v-maska="'##:##'"
                                class="input-width-100"/>
                -
                <el-time-select v-model="formData.time_break_to" placeholder="__:__"
                                :start="formData.time_break_from || '08:00'"
                                step="00:30" end="18:00"
                                class="input-width-100"
                                v-maska="'##:##'"
                />
              </div>

            </el-form-item>
          </div>
          <el-divider/>
          <div class="row cols-2 small-gaps">
            <div v-for="side in ['sender', 'receiver']" :key="side" :class="`form-${side} form-column`">
              <div class="form-column-title">{{ side === 'sender' ? 'Отправитель' : 'Получатель' }}</div>
              <el-form-item label="Город" required :prop="side === 'sender' ? 'city_from' : 'city_to'">
                <el-select
                    v-model="formData[side === 'sender' ? 'city_from' : 'city_to']"
                    @change="calculate();
                    formData[`${side}_address`] = '';
                    onCitySelect(side)"
                    clearable
                    filterable
                    autocomplete="new-password"
                    :placeholder=" side === 'sender' ? 'Город отправления' : 'Город получения'"
                    class="input-width-100">
                  <el-option v-for="city in cities.filter(c=>c.filial*1 === 1)" :key="city.id"
                             :label="city.name" :value="city.id"/>
                </el-select>
              </el-form-item>
              <el-radio-group v-model="formData[`${side}_delivery_type`]"
                              class="row cols-2 small-gaps delivery-type-buttons"
                              @change="formData[`${side}_address`] = ''; formData[`${side}_terminal`] = ''">
                <el-radio border label="terminal" class="form-radio-button">Терминал</el-radio>
                <el-radio border label="address" class="form-radio-button">Адрес</el-radio>
              </el-radio-group>
              <el-form-item v-if="formData[`${side}_delivery_type`] === 'address'"
                            :prop="`${side}_address`" label="Адрес" required>
                <el-autocomplete v-model="formData[`${side}_address`]"
                                 :fetch-suggestions="(q, cb)=>findByAddress(q, cb, side)"
                                 :debounce="300"
                                 clearable
                                 autocomplete="new-password"
                                 :disabled="!formData[side === 'sender' ? 'city_from' : 'city_to']"
                                 @select="v=>calculateDistance(v, side)"
                                 class="input-width-100"
                                 :placeholder="!formData[side === 'sender' ? 'city_from' : 'city_to'] ? 'Сначала выберите город': 'Улица, дом...'">
                  <template #default="{item}">
                    <div class="address-suggestion">
                      <div class="address-suggestion-title">{{ item.title }}</div>
                      <div class="address-suggestion-subtitle">{{ item.subtitle }}</div>
                    </div>
                  </template>
                </el-autocomplete>
              </el-form-item>
              <el-form-item v-else :prop="`${side}_terminal`" label="Терминал" required>
                <el-select v-model="formData[`${side}_terminal`]"
                           clearable
                           autocomplete="new-password"
                           :disabled="!formData[side === 'sender' ? 'city_from' : 'city_to']"
                           class="input-width-100"
                           :placeholder="!formData[side === 'sender' ? 'city_from' : 'city_to'] ? 'Сначала выберите город': 'Выберите из списка'"
                           no-data-text="Сначала выберите город">
                  <el-option v-for="terminal in
                  terminals.filter(_terminal=>_terminal.city === cities.find(city=>city.id === formData[[side === 'sender' ? 'city_from' : 'city_to']])?.name)"
                             :key="terminal.id"
                             :label="`${terminal.name}, ${terminal.address}`"
                             :value="terminal.id"/>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-radio-group v-model="formData[`${side}_is`]" class="row cols-2 small-gaps"
                                @change="formData.payment_type = 0">
                  <el-radio border label="entity" class="form-radio-button">Юр.лицо</el-radio>
                  <el-radio border label="individual" class="form-radio-button">Физ.лицо</el-radio>
                </el-radio-group>
              </el-form-item>
              <template v-if="formData[`${side}_is`] === 'entity'">
                <el-form-item label="ИНН" required :prop="`${side}_inn`">
                  <el-autocomplete v-model="formData[`${side}_inn`]" placeholder="ИНН" v-maska="store.state.mask_inn"
                                   :fetch-suggestions="findUserCompanies" :select-when-unmatched="true"
                                   @select="obj => onSelectCompany(obj, side)">
                    <template #append>
                      <el-button @click="findByInn(side)">
                        <template #icon>
                          <font-awesome-icon icon="fa-solid fa-magnifying-glass"/>
                        </template>
                        Искать
                      </el-button>
                    </template>
                  </el-autocomplete>
                </el-form-item>
                <el-form-item label="КПП">
                  <el-input v-model="formData[`${side}_kpp`]"
                            :disabled="formData[`${side}_inn`].length > 10"/>
                </el-form-item>
                <el-form-item label="Наименование компании" required :prop="`${side}_title`">
                  <el-input v-model="formData[`${side}_title`]"/>
                </el-form-item>
              </template>
              <el-form-item :prop="`${side}_name`" label="Контактное лицо" required>
                <el-input v-model="formData[`${side}_name`]" placeholder="ФИО"
                          autocomplete="new-password"/>
              </el-form-item>
              <el-form-item :prop="`${side}_phone`" label="Контактный телефон" required>
                <el-input v-model="formData[`${side}_phone`]" placeholder="+7 (   )"
                          v-maska="store.state.phone_mask"
                          autocomplete="new-password"/>
              </el-form-item>
              <el-form-item v-if="side ==='sender' ? isVisibleSecondPhoneFrom : isVisibleSecondPhoneTo"
                            :prop="`${side}_phone2`" label="Доп. контактный телефон">
                <el-input v-model="formData[`${side}_phone2`]" placeholder="+7 (   )"
                          v-maska="store.state.phone_mask"
                          autocomplete="new-password"/>
              </el-form-item>
              <el-button v-if="side ==='sender' ? !isVisibleSecondPhoneFrom : !isVisibleSecondPhoneTo" text
                         @click="side ==='sender' ? isVisibleSecondPhoneFrom = !isVisibleSecondPhoneFrom : isVisibleSecondPhoneTo = !isVisibleSecondPhoneTo">
                + Добавить контактный телефон
              </el-button>
              <div style="display: flex; flex-direction: column">
                <el-switch v-model="formData[`${side}_order_pass`]" active-text="Заказать пропуск на въезд"
                           :active-value="1" :inactive-value="0"/>
                <el-switch v-model="formData[`${side}_driver_info`]" active-text="Данные водителя"
                           :active-value="1" :inactive-value="0"/>
                <el-switch v-model="formData[`${side}_appointment`]" active-text="Запись на отгрузку"
                           :active-value="1" :inactive-value="0"/>
                <el-switch v-model="formData[`${side}_loading`]" active-text="Требуются погрузочные работы"
                           :active-value="1" :inactive-value="0"/>
              </div>
            </div>
          </div>
          <el-divider/>
          <div class="form-column-title">Параметры груза и условия его&nbsp;получения</div>
          <div class="row cols-2 small-gaps">
            <div class="form-column">
              <el-form-item label="Наименование груза" required prop="naim">
                <el-tree-select v-model="formData.naim"
                                :data="store.state.categories"
                                class="input-width-100" placeholder="Например, Стройматериалы"
                                :render-after-expand="false"/>
              </el-form-item>
              <el-form-item label="Упаковка" prop="wrapping">
                <el-input v-model="formData.wrapping" placeholder="Укажите упаковку груза"/>
              </el-form-item>
            </div>
            <div class="form-column">
              <el-form-item label="Доп. упаковка" prop="upak_types">
                <div style="display: flex; flex-direction: column">
                  <el-switch
                      v-for="(option, key) in store.state.wrap_options" :key="key"
                      :label="option"
                      v-model="formData[key]" :active-value="1" :inactive-value="0"
                      @change="calculate"
                      :active-text="option"/>
                </div>
              </el-form-item>
            </div>
          </div>
          <div class="row cols-2 small-gaps">
            <div class="col"
                 style="display: grid; grid-template-columns: repeat(3,1fr); gap: 4px; align-items: flex-end">
              <el-form-item label="Габариты" prop="length">
                <el-input v-model="formData.length"
                          ref="lengthRef"
                          @input="onInputDimensions('length')"
                          class="dimensions-input" placeholder="ДД">
                  <template #append>м</template>
                </el-input>
              </el-form-item>
              <el-form-item prop="width">
                <el-input v-model="formData.width" class="dimensions-input" placeholder="ШШ"
                          ref="widthRef"
                          @input="onInputDimensions('width')">
                  <template #append>м</template>
                </el-input>
              </el-form-item>
              <el-form-item prop="height">
                <el-input v-model="formData.height" class="dimensions-input" placeholder="ВВ"
                          ref="heightRef"
                          @input="onInputDimensions('height')">
                  <template #append>м</template>
                </el-input>
              </el-form-item>
            </div>
            <div class="row cols-2 small-gaps">
              <div class="col">
                <el-form-item label="Общий вес" required prop="ves">
                  <el-input v-model="formData.ves"
                            @input="onInputDimensions('ves')" ref="vesRef">
                    <template #append>кг</template>
                  </el-input>
                </el-form-item>
              </div>
              <div class="col">
                <el-form-item label="Общий объем" required prop="obem">
                  <el-input v-model="formData.obem"
                            @input="onInputDimensions('obem')" ref="obemRef">
                    <template #append>м<sup>3</sup></template>
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="row cols-3">
            <div class="col">
              <el-form-item label="Количество мест" required prop="mest">
                <el-input v-model="formData.mest"
                          @input="onInputDimensions('mest')" ref="mestRef"/>
              </el-form-item>
            </div>
          </div>
          <div class="row cols-2 small-gaps">
            <div class="col">
              <el-form-item>
                <el-switch v-model="formData.temperature" active-text="Температурный режим"/>
              </el-form-item>
              <div class="temperature-range">
                <el-form-item label="Температура от">
                  <el-select v-model="formData.temp_from" :disabled="!formData.temperature"
                             placeholder="Выберите из списка" clearable>
                    <el-option v-for="item in temperaturesFrom" :key="item" :value="item" :label="`${item} &#8451;`"/>
                  </el-select>
                </el-form-item>
                <el-form-item label="Температура до">
                  <el-select v-model="formData.temp_to" :disabled="!formData.temperature"
                             placeholder="Выберите из списка" clearable>
                    <el-option v-for="item in temperaturesTo" :key="item" :value="item" :label="`${item} &#8451;`"/>
                  </el-select>
                </el-form-item>
              </div>
              <el-form-item>
                <el-switch v-model="formData.fragile" active-text="Хрупкий груз"
                           :active-value="1" :inactive-value="0"/>
              </el-form-item>
              <el-form-item>
                <el-switch v-model="formData.isOsg" active-text="Страхование груза"/>
                <el-input :style="`opacity: ${formData.isOsg ? 1 : 0}`"
                          v-model="formData.osg"
                          placeholder="Стоимость груза"
                          v-maska="store.state.maskThousandSeparator">
                  <template #append>₽</template>
                </el-input>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item>
                <el-switch v-model="formData.isSender_viezd" active-text="Въезд на территорию платный"/>
              </el-form-item>
              <el-form-item label="Стоимость">
                <el-input v-model="formData.sender_viezd" :disabled="!formData.isSender_viezd"
                          v-maska="store.state.maskThousandSeparator">
                  <template #append>₽</template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-switch v-model="formData.bulky" active-text="Наличие нестандартных мест"
                           :active-value="1" :inactive-value="0"/>
              </el-form-item>
              <el-form-item>
                <el-switch v-model="formData.special_conditions" active-text="Требуются особые условия перевозки"
                           :active-value="1" :inactive-value="0"/>
              </el-form-item>
            </div>
          </div>
          <el-divider/>
          <div class="form-column-title">Дополнительно</div>
          <div class="row cols-2 small-gaps">
            <div class="col">
              <el-switch v-model="formData.proxy_receiver" :active-value="1" :inactive-value="0"
                         active-text="По доверенности получателя"/>
              <el-switch v-model="formData.documents_return" :active-value="1" :inactive-value="0"
                         active-text="Возврат документов"/>
            </div>
            <div class="col">
              <el-form-item label="Тип погрузки">
                <el-select v-model="formData.pogr_type" class="input-width-100" placeholder="Выберите из списка">
                  <el-option v-for="item in ['Задняя', 'Боковая', 'Верхняя']" :key="item" :value="item" :label="item"/>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <el-form-item label="Комментарий к заявке">
            <el-input v-model="formData.comment_client" type="textarea" :autosize="{minRows: 5, maxRows: 10}"/>
          </el-form-item>
          <el-divider/>
          <div class="form-column-title">Оплата</div>
          <div class="row">
            <div class="col">
              <el-form-item label="Кто оплачивает">
                <el-radio-group v-model="formData.payment_payer" class="row cols-3 payment-buttons"
                                @change="onChangePayer">
                  <el-radio border size="large" label="sender" class="form-radio-button">Отправитель</el-radio>
                  <el-radio border size="large" label="receiver" class="form-radio-button">Получатель</el-radio>
                  <el-radio border size="large" label="third_party" class="form-radio-button">Третье лицо</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
          <div class="row cols-3" v-if="formData.payment_payer === 'third_party'">
            <div class="col">
              <el-form-item label="ИНН">
                <el-input v-model="formData.payer_inn"
                          placeholder="ИНН"
                          v-maska="store.state.mask_inn">
                  <template #append>
                    <el-button @click="findByInn('payer')">
                      <template #icon>
                        <font-awesome-icon icon="fa-solid fa-magnifying-glass"/>
                      </template>
                      Искать
                    </el-button>
                  </template>
                </el-input>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item label="КПП">
                <el-input v-model="formData.payer_kpp"
                          :disabled="formData.payer_inn.length === 12"/>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item label="Наименование компании">
                <el-input v-model="formData.payer_title"/>
              </el-form-item>
            </div>
            <!--            <div class="col">
                          <el-form-item label="БИК">
                            <el-autocomplete v-model="formData.payer_bik" :fetch-suggestions="findByBik"
                                             class="input-width-100"
                                             placeholder="Поиск по БИК"
                                             no-data-text="Компания не найдена" no-match-text="Компания не найдена"
                                             @select="onSelectCompanyByBik"
                                             v-maska="store.state.mask_bik"/>
                          </el-form-item>
                        </div>-->
          </div>
          <div class="row cols-2">
            <!--            <div class="col">
                          <el-form-item label="Наименование компании">
                            <el-input v-model="formData.payer_title"/>
                          </el-form-item>
                        </div>-->
            <!--            <div class="col">
                          <el-form-item label="Юридический адрес">
                            <el-input v-model="formData.payer_address"/>
                          </el-form-item>
                        </div>-->
          </div>
          <!--          <div class="row cols-2">
                      <div class="col">
                        <el-form-item label="Р/с">
                          <el-input v-model="formData.payer_rs"/>
                        </el-form-item>
                      </div>
                      <div class="col">
                        <el-form-item label="К/с">
                          <el-input v-model="formData.payer_ks"/>
                        </el-form-item>
                      </div>
                    </div>-->
          <div class="row">
            <el-form-item label="Способ оплаты">
              <el-radio-group v-model="formData.payment_type" class="row cols-3 payment-buttons">
                <el-radio border size="large" :label="0" class="form-radio-button">Наличные/банк. карта</el-radio>
                <el-radio :disabled="formData[`${formData.payment_payer}_is`] === 'individual'" border size="large"
                          :label="1" class="form-radio-button">Безналич. оплата
                </el-radio>
              </el-radio-group>
              <div class="payment-hint">
                Оплата по безналичной оплате доступна только юр. лицам
              </div>
            </el-form-item>
          </div>
          <el-divider/>
          <div class="form-actions">
            <el-button @click="sendFormAsTemplate" class="send-form-button" :loading="templateLoading"
                       :disabled="formLoading">Сохранить как черновик
            </el-button>
            <el-button @click="sendForm" :loading="formLoading" :disabled="templateLoading"
                       class="send-form-button" type="primary">
              <template #loading>
                <Vue3Lottie :animationData="lottieTruckJSON" :height="90" :width="100"/>
              </template>
              {{ formLoading ? '' : 'Сохранить и отправить' }}
            </el-button>
          </div>
        </el-form>

      </el-card>
      <el-card class="sbor-form-payment">
        <el-collapse class="collapse-no-border" v-model="sideMenuCollapse">
          <el-collapse-item name="price">
            <template #title>
              <div class="sbor-form-payment-header">
                <div class="sbor-form-payment__title">Стоимость</div>
                <div v-if="!sideMenuCollapse.includes('price')"
                     class="sbor-form-payment-header__price">
                  {{ globalMixins.methods.thousandSeparator(calculatedData.sum.toFixed(2)) }} ₽
                </div>
              </div>
            </template>
            <div class="sbor-form-payment-list">
              <div class="sbor-form-payment-list__item" v-for="(item, key) in calculatedData.items" :key="key">
                <div class="sbor-form-payment-list__item_label">
                  <span>{{ item.title }}</span>
                  <div v-if="item.sum === 0" class="sbor-form-payment-list__item_hint">Стоимость уточняйте у менеджера
                  </div>
                </div>
                <div class="sbor-form-payment-list__item_price">
                  {{ item.sum > 0 ? globalMixins.methods.thousandSeparator(item.sum.toFixed(2)) : '- - - ' }} ₽
                </div>
              </div>
            </div>
            <div class="sbor-form-payment-total">
              <div class="sbor-form-payment-total__title">Итого:</div>
              <div class="sbor-form-payment-total__price">
                {{ globalMixins.methods.thousandSeparator(calculatedData.sum.toFixed(2)) }} ₽
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div class="order-info">
          <el-alert
              v-if="Object.keys(noPriceCalculatedItems).length"
              title="Итоговая стоимость указана без следующих услуг:"
              type="info"
              show-icon
              :closable="false"
              style="margin-bottom: 8px">
            <template #default>
              <ul class="order-info-list" style="list-style-type: disc">
                <li class="order-info-list__item" v-for="(item, key) in noPriceCalculatedItems" :key="key">
                  {{ item.title }}
                </li>
              </ul>
            </template>
          </el-alert>
          <el-alert
              title="Стоимость заказа может измениться"
              type="info"
              show-icon
              :closable="false">
            <template #default>
              <ul class="order-info-list">
                <li class="order-info-list__item">При изменении габаритов или веса груза после замера на терминале</li>
                <li class="order-info-list__item">При редактировании дополнительных услуг</li>
              </ul>
            </template>
          </el-alert>
        </div>
      </el-card>
    </div>
  </div>
  <InfoDialog ref="info_dialog" @on-ok-click="router.push({name: 'OrdersPage', params: {city: currentCityCode.value}})"/>
</template>

<script setup>
import lottieTruckJSON from '@/assets/lottie_truck.json';
import {ref, reactive, onMounted, computed, inject, watch} from 'vue';
import {useRouter, useRoute} from "vue-router";
import {useStore} from "vuex";
import globalMixins from "@/globalMixins";
import InfoDialog from "@/dialogs/InfoDialog";

const store = useStore();
const route = useRoute();
const router = useRouter();
const moment = inject('moment');
const form = ref();
const info_dialog = ref();
const temperatures = ref([]);
for (let i = 0; i <= 25; i++) {
  temperatures.value.push(i);
}
const temperaturesTo = computed(() => {
  let temps = [...temperatures.value];
  let tempFromIndex = temps.indexOf(formData.temp_from);
  return tempFromIndex < 0 ? temps : temps.splice(tempFromIndex + 1);
});
const temperaturesFrom = computed(() => {
  return temperatures.value;
});

let dates = reactive({closest: [], all: []});
const authData = reactive({email: '', token: '', code: ''})
let formData = reactive({
  phone: '',
  id: '',
  date: '',
  time_from: '',
  time_to: '',
  time_break_from: '',
  time_break_to: '',
  city_from: '',
  city_to: '',
  sender_delivery_type: 'address',
  sender_is: 'entity',
  sender_title: '',
  sender_address: '',
  sender_terminal: '',
  sender_inn: '',
  sender_kpp: '',
  sender_phone: '',
  sender_phone2: '',
  sender_name: '',
  sender_order_pass: '',
  sender_driver_info: '',
  sender_appointment: '',
  sender_loading: '',
  receiver_delivery_type: 'terminal',
  receiver_is: 'entity',
  receiver_title: '',
  receiver_address: '',
  receiver_terminal: '',
  receiver_inn: '',
  receiver_kpp: '',
  receiver_name: '',
  receiver_phone: '',
  receiver_phone2: '',
  receiver_order_pass: '',
  receiver_driver_info: '',
  receiver_appointment: '',
  receiver_loading: '',

  naim: '',
  upak_types: [],
  ves: '',
  obem: '',
  mest: '',
  sizes: '',
  length: '',
  width: '',
  height: '',
  temperature: false,
  temp_from: '',
  temp_to: '',
  fragile: 0,
  pogr_type: 'Задний',
  comment_client: '',
  sender_viezd: '',
  isSender_viezd: false,
  wrapping: '',
  isOsg: false,
  osg: '',
  source_distance: '',
  destination_distance: '',
  special_conditions: 0,
  bulky: 0,
  lathing: 0,
  stretch_wrap: 0,
  bubble_wrap: 0,

  entrance_price: '',

  proxy_receiver: 0,
  documents_return: 0,

  payment_type: 0,
  payment_payer: '',
  payer_title: '',
  payer_address: '',
  payer_inn: '',
  payer_bik: '',
  payer_kpp: '',
  payer_rs: '',
  payer_ks: '',
  file_pdf: ''
});
let isVisibleSecondPhoneFrom = ref(false);
let isVisibleSecondPhoneTo = ref(false);
let formLoading = ref(false);
let templateLoading = ref(false);
const widthRef = ref();
const lengthRef = ref();
const heightRef = ref();
const vesRef = ref();
const obemRef = ref();
const mestRef = ref();

const sizesRefs = {width: widthRef, length: lengthRef, height: heightRef, ves: vesRef, obem: obemRef, mest: mestRef};

const dimensionsValidator = (rule, val, callback, param, errorMaxDimension) => {
  if (val > store.state.maxDimensionSizes[param] && sizesRefs[param]?.value) {
    sizesRefs[param].value.select();
    callback(new Error(`Не более ${store.state.maxDimensionSizes[param]} ${errorMaxDimension}`));
  } else callback();
}

const rules = reactive({
  phone: [
    {required: true, message: 'Укажите контактный телефон', trigger: 'blur'},
    {required: true, message: 'Укажите контактный телефон', trigger: 'change'},
  ],
  date: [
    {required: true, message: 'Укажите дату', trigger: 'blur'},
    {required: true, message: 'Укажите дату', trigger: 'change'},
  ],
  city_from: [
    {required: true, message: 'Укажите город отправки', trigger: 'blur'},
    {required: true, message: 'Укажите город отправки', trigger: 'change'},
  ],
  city_to: [
    {required: true, message: 'Укажите город получения', trigger: 'blur'},
    {required: true, message: 'Укажите город получения', trigger: 'change'},
  ],
  sender_address: [
    {required: true, message: 'Укажите адрес отправки', trigger: 'blur'},
    {required: true, message: 'Укажите адрес отправки', trigger: 'change'},
  ],
  sender_terminal: [
    {required: true, message: 'Укажите терминал отправки', trigger: 'blur'},
    {required: true, message: 'Укажите терминал отправки', trigger: 'change'},
  ],
  receiver_address: [
    {required: true, message: 'Укажите адрес получения', trigger: 'blur'},
    {required: true, message: 'Укажите адрес получения', trigger: 'change'},
  ],
  receiver_terminal: [
    {required: true, message: 'Укажите терминал получения', trigger: 'blur'},
    {required: true, message: 'Укажите терминал получения', trigger: 'change'},
  ],
  sender_title: [
    {required: true, message: 'Укажите название компании', trigger: 'blur'},
    {required: true, message: 'Укажите название компании', trigger: 'change'},
  ],
  receiver_title: [
    {required: true, message: 'Укажите название компании', trigger: 'blur'},
    {required: true, message: 'Укажите название компании', trigger: 'change'},
  ],
  sender_inn: [
    {required: true, message: 'Укажите ИНН', trigger: 'blur'},
    {required: true, message: 'Укажите ИНН', trigger: 'change'},
  ],
  receiver_inn: [
    {required: true, message: 'Укажите ИНН', trigger: 'blur'},
    {required: true, message: 'Укажите ИНН', trigger: 'change'},
  ],
  sender_name: [
    {required: true, message: 'Укажите ФИО', trigger: 'blur'},
    {required: true, message: 'Укажите ФИО', trigger: 'change'},
  ],
  receiver_name: [
    {required: true, message: 'Укажите ФИО', trigger: 'blur'},
    {required: true, message: 'Укажите ФИО', trigger: 'change'},
  ],
  sender_phone: [
    {required: true, message: 'Укажите телефон', trigger: 'blur'},
    {required: true, message: 'Укажите телефон', trigger: 'change'},
  ],
  receiver_phone: [
    {required: true, message: 'Укажите телефон', trigger: 'blur'},
    {required: true, message: 'Укажите телефон', trigger: 'change'},
  ],
  naim: [
    {required: true, message: 'Обязательное поле', trigger: 'blur'},
    {required: true, message: 'Обязательное поле', trigger: 'change'},
  ],
  width: [
    {trigger: 'blur', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'width', 'м')},
    {trigger: 'change', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'width', 'м')},
    {required: true, message: 'Укажите ширину', trigger: 'blur'},
    {required: true, message: 'Укажите ширину', trigger: 'change'},
  ],
  length: [
    {trigger: 'blur', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'length', 'м')},
    {trigger: 'change', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'length', 'м')},
    {required: true, message: 'Укажите длину', trigger: 'blur'},
    {required: true, message: 'Укажите длину', trigger: 'change'},
  ],
  height: [
    {trigger: 'blur', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'height', 'м')},
    {trigger: 'change', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'height', 'м')},
    {required: true, message: 'Укажите высоту', trigger: 'blur'},
    {required: true, message: 'Укажите высоту', trigger: 'change'},
  ],
  ves: [
    {trigger: 'blur', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'ves', 'кг')},
    {trigger: 'change', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'ves', 'кг')},
    {required: true, message: 'Укажите вес', trigger: 'blur'},
    {required: true, message: 'Укажите вес', trigger: 'change'},
  ],
  mest: [
    {trigger: 'blur', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'mest', 'мест')},
    {trigger: 'change', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'mest', 'мест')},
    {required: true, message: 'Укажите кол-во мест', trigger: 'blur'},
    {required: true, message: 'Укажите кол-во мест', trigger: 'change'},
  ],
  obem: [
    {trigger: 'blur', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'obem', 'м3')},
    {trigger: 'change', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'obem', 'м3')},
    {required: true, message: 'Укажите объем', trigger: 'blur'},
    {required: true, message: 'Укажите объем', trigger: 'change'},
  ]
})

const calculatedData = reactive({
  sum: 0, withoutNDS: 0, items: {}
});

const api = inject('api');
const sideMenuCollapse = ref(['price']);
const datepicker = ref(null);

const emailError = ref('');
const codeError = ref('');

const orders = ref([]);
const prevOrder = ref('');

const isViewing = ref(false);
const getCode = () => {
  api.request('auth/login', {email: authData.email, register: 2}).then((data) => {
    authData.token = data.response;
  }).catch(data => {
    emailError.value = data.error?.text || data.error;
  })
}
const checkCode = () => {
  api.request('auth/checkCode', {code: authData.code, token: authData.token}).then(data => {
    if (data.response) {
      if (!data.response.currentCompany) {
        let ids = Object.keys(data.response.companies);
        if (ids.length) {
          data.response.currentCompany = data.response.companies[ids[0]];
          localStorage.setItem('currentCompanyId', ids[0]);
        }
      }
      store.commit('setUser', data.response);
    }
    getOrders();
  }).catch(data => {
    codeError.value = data.error?.text || data.error;
  })
}

const getDates = () => {
  api.request('order/dates').then(data => {
    formData.date = data.response.closest[0];
    Object.assign(dates, data.response);
  })
}

const prepareDataToRequest = () => {
  let data = {...formData};
  let maskedValuesWithSpace = ['osg', 'destination_distance', 'source_distance', 'ves', 'obem', 'length', 'width', 'height'];
  maskedValuesWithSpace.forEach(val => {
    if (formData[val]) {
      data[val] = formData[val].toString().replaceAll(' ', '');
    }
  })
  return data;
}
const calculate = () => {
  api.request('order/calculate', prepareDataToRequest()).then((data) => {
    Object.assign(calculatedData, data.response);
  });
}

const onChangePayer = (value) => {
  if (value === 'sender') {
    formData.payer_title = formData.sender_title;
    formData.payer_inn = formData.sender_inn;
    formData.payer_kpp = formData.sender_kpp;
  } else if (value === 'receiver') {
    formData.payer_title = formData.receiver_title;
    formData.payer_inn = formData.receiver_inn;
    formData.payer_kpp = formData.receiver_kpp;
  } else {
    formData.payer_title = '';
    formData.payer_inn = '';
    formData.payer_kpp = '';
  }
}

const selectDate = (date) => {
  form.value.clearValidate(['date']);
  formData.date = date;
}

const disabledDates = (date) => {
  return moment(date).format('YYYY-MM-DD')<moment().format('YYYY-MM-DD')
  // return !dates.all.includes(moment(date).format('YYYY-MM-DD'));
}
const sendForm = () => {
  if (!user.value.id) checkCode();
  form.value.validate(isValid => {
    if (isValid) {
      formLoading.value = true;
      api.request(`order/${formData.id ? `update/${formData.id}` : 'create'}`, {
        ...prepareDataToRequest(),
        status: 1
      }).then(data => {
        formLoading.value = false;
        if (!formData.id && data.response.code) {
          info_dialog.value.open('Спасибо за заказ!', `Мы скоро с вами свяжемся.
          ${data.response.code} - код заявки, по которому будут принимать груз`, data.response.file_pdf && data.response.receiver_terminal ? {url: data.response.file_pdf, label: 'Скачать заявку'} : null);
        }
      }).catch(() => {
        formLoading.value = false;
      })
    }
  })
}

const sendFormAsTemplate = () => {
  if (!user.value.id) checkCode();
  templateLoading.value = true;
  api.request(`order/${formData.id ? `update/${formData.id}` : 'create'}`, {
    ...prepareDataToRequest(),
    status: 0
  }).then(data => {
    templateLoading.value = false;
    router.push({name: 'OrdersPage', params: {city: currentCityCode.value}});
  }).catch(() => {
    templateLoading.value = false;
  })
}

const findByInn = (side) => {
  api.request(`user/findByINN?inn=${formData[`${side}_inn`]}`).then(data => {
    onSelectCompanyByInn(side, data.response);
  });
}

const onSelectCompanyByInn = (type, value) => {
  formData[`${type}_kpp`] = value.kpp;
  formData[`${type}_title`] = value.name;
}

const onCitySelect = (side) => {
  formData[`${side}_terminal`] = terminals.value
      .find(t => t.city === cities.value.find(c => c.id === formData[side === 'sender' ? 'city_from' : 'city_to'])?.name)?.id;
}
/*
const findByBik = async (q, cb) => {
  let result = await api.request(`user/findByBik?bik=${q}`).then(data => {
    return [data.response];
  });
  result = result.filter(i => i).map(i => {
    i.value = i.name;
    i.bik = q;
    return i;
  });
  cb(result);
}

const onSelectCompanyByBik = (value) => {
  formData.payer_ks = value.ks;
  formData.payer_bik = value.bik;
}*/

const getOrder = (id, clearId = false) => {
  api.request(`order/get/${id}${route.params.token ? `/${route.params.token}` : ''}`).then(data => {
    isViewing.value = +data.response.status > 0 || (!user.value.id && route.params.token);
    let dateBeforeReceived = formData.date;
    let phoneBeforeReceived = formData.phone;
    Object.assign(formData, data.response);
    formData.date = dateBeforeReceived;
    parseFromResponse(data.response);
    if (clearId) {
      formData.id = '';
      formData.code = '';
      formData.file_pdf = '';
      formData.phone = phoneBeforeReceived;
      formData.status = 0;
    }
    if (formData.sender_terminal) formData.sender_delivery_type = 'terminal';
    if (formData.receiver_terminal) formData.receiver_delivery_type = 'terminal';
    formData.temperature = !!(data.response.temp_from || data.response.temp_to);
    formData.isSender_viezd = !!data.response.sender_viezd;
    calculate();
  });
}

const parseFromResponse = (data) => {
  convertParamsToInt();
  if (data.osg * 1) formData.isOsg = true;
  let zeroValues = ['sender_viezd', 'osg', 'sender_terminal', 'receiver_terminal', 'city_from', 'city_to'];
  zeroValues.forEach(value => {
    if (!data[value]) formData[value] = '';
  })
  let valuesToInt = ['sender_order_pass', 'sender_driver_info', 'sender_appointment', 'sender_loading',
    'receiver_order_pass', 'receiver_driver_info', 'receiver_appointment', 'receiver_loading', 'stretch',
    'special_conditions', 'bulky', 'lathing', 'stretch_wrap', 'bubble_wrap', 'entrance_price', 'proxy_receiver', 'documents_return', 'fragile'];
  valuesToInt.forEach(key => {
    formData[key] = formData[key] * 1;
  });
  if (!data.sender_inn) formData.sender_is = 'individual';
  if (!data.receiver_inn) formData.receiver_is = 'individual';
  formData.sender_delivery_type = data.sender_address ? 'address' : 'terminal';
  formData.receiver_delivery_type = data.receiver_address ? 'address' : 'terminal';
  isVisibleSecondPhoneFrom = !!data.sender_phone2;
  isVisibleSecondPhoneTo = !!data.receiver_phone2;
  if (data.sender_inn === data.payer_inn && data.sender_kpp === data.payer_kpp)
    formData.payment_payer = 'sender';
  else if (data.receiver_inn === data.payer_inn && data.receiver_kpp === data.payer_kpp)
    formData.payment_payer = 'receiver';
  else formData.payment_payer = 'third_party'
}

const openDatePicker = () => {
  datepicker.value.handleOpen();
}

const findByAddress = async (q, cb, type) => {
  let result = await api.request('order/findAddress',
      {q, city: type === 'sender' ? formData.city_from : formData.city_to}).then(data => {
    return data.response.map(addr => {
      addr.value = addr.title;
      return addr;
    });
  })
  cb(result);
}

const calculateDistance = (addr, side) => {
  api.request('order/expDistance', {
    lat: addr.lat,
    lng: addr.lng,
    city: side === 'sender' ? formData.city_from : formData.city_to
  }).then(data => {
    if (side === 'sender') formData.source_distance = data.response;
    else formData.destination_distance = data.response;
  })
}

const getOrders = () => {
  api.request('order/get').then(data => {
    orders.value = data.response.filter(order => order.city_from);
  })
}

const onInputDimensions = (param) => {
  formData[param] = globalMixins.methods.checkIntOrFloatInput(formData[param], store.state.maxDimensionSizes[param]);
}

const convertParamsToInt = () => {
  const intParams = ['city_from', 'city_to', 'sender_terminal', 'receiver_terminal'];
  intParams.forEach(param => {
    if (formData[param] !== '')
      formData[param] = formData[param] * 1;
  })
}

const findUserCompanies = (q, cb) => {
  let result = [];
  let companiesIds = Object.keys(user.value.companies);
  companiesIds.forEach(key => {
    if (user.value.companies[key].inn.includes(q) && !result.filter(r => r.id === key).length)
      result.push(user.value.companies[key]);
  })
  result = result.map(r => {
    r.value = r.inn;
    return r;
  })
  cb(result);
}

const onSelectCompany = (obj, side) => {
  formData[`${side}_inn`] = obj.inn;
  formData[`${side}_kpp`] = obj.kpp;
  formData[`${side}_title`] = obj.name;
}

onMounted(async () => {
  getDates();
  getOrders();
  formData.phone = user.value.phone;
  await router.isReady();
  if (route.query.copy) {
    getOrder(route.query.item_id, true);
  } else {
    formData.city_from = cities.value.find(c => c.code === currentCityCode.value)?.id || 1;
  }
  if (route.params.id) getOrder(route.params.id);
  else calculate();
})

const user = computed(() => {
  return store.getters.getUser;
})

const cities = computed(() => store.state.cities);
const terminals = computed(() => store.state.terminals);
const currentCityCode = computed(() => store.state.currentCityCode);

const noPriceCalculatedItems = computed(() => Object.fromEntries(Object.entries(calculatedData.items).filter(([key, _item]) => _item.sum === 0)));
watch(() => store.state.user, () => {
  formData.phone = user.value.phone;
});

watch([() => cities, () => currentCityCode], () => {
  formData.city_from = cities.value.find(c => c.code === currentCityCode.value)?.id || 1;
})
</script>

<style scoped lang="scss">
.sbor-form-page {
  max-width: var(--desktop-maxwidth);
  margin: 0 auto;
  padding: 185px var(--hor-padding) 100px;

  &__profile {
    padding-top: 0;
  }

  .sbor-form-content {
    position: relative;

    &-overlay {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: rgba(0, 0, 0, .1);
      cursor: not-allowed;
    }
  }

  &-container {
    display: grid;
    grid-template-columns: 1fr 350px;
    grid-column-gap: 20px;

    .inline-calendar {
    }

    .inline-calendar-container {
      display: flex;
      column-gap: 12px;
      border: 1px solid var(--border-color);
      border-radius: 4px;
      padding: 0 8px;

      .dates {
        display: flex;
        padding: 8px 0;
      }

      .other-date-picker {
        padding-left: 8px;
        border-left: 1px solid var(--border-color);
      }

      .date {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        width: 60px;
        height: 100%;
        min-width: 60px;
        padding: 8px;
        border-radius: 4px;
        cursor: pointer;
        border: 1px solid var(--border-color);
        box-shadow: 1px 2px 0 rgba(0, 0, 0, .2);

        &:last-child {
          margin-right: 0;
        }

        &.selected {
          background: var(--el-color-primary);
          box-shadow: 1px 2px 0 rgba(122, 116, 116, 0.2);
          color: #FFF;
        }

        &__day {
          margin-bottom: 4px;
          line-height: 100%;
          font-size: 15px;
        }

        &__weekday {
          font-size: 11px;
          line-height: 100%;

          &.weekend {
            opacity: .3;
          }
        }
      }
    }

    .delivery-type-buttons {
      margin-bottom: 8px;
    }

    .sbor-form-payment {
      position: sticky;
      top: 140px;
      height: fit-content;
      max-height: calc(100vh - 160px);
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }

      &-header {
        padding-right: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;

        &__price {
          font-size: var(--headline-3);
          font-weight: 500;
        }
      }

      &__title {
        font-size: var(--headline-3);
      }

      &-list {
        border-bottom: 1px dashed var(--border-color);

        &__item {
          padding: 8px 0;
          display: flex;
          justify-content: space-between;
          align-items: flex-start;

          &_hint {
            font-size: 10px;
            opacity: .5;
          }
        }
      }

      &-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 24px;
        gap: 20px;

        &__title {
          font-size: var(--headline-3);
        }

        &__price {
          font-size: var(--headline-3);
          font-weight: 500;
          width: fit-content;

          &.no-price {
            font-size: 14px;
            font-weight: normal;
            line-height: 100%;
          }
        }
      }
    }
  }

  .sbor-form {
    width: 100%;
  }

  &-title {
    margin-bottom: 40px;
    font-size: var(--headline-1);
  }

  .time-range {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
  }

  .sbor-form-time {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
  }

  .form-radio-buttons {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;

    .form-radio-button {
      width: 100%;
    }
  }

  .form-column-title {
    margin-bottom: 24px;
    font-size: var(--headline-2);
    font-weight: 500;
  }

  .form-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
  }

  .temperature-range {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 12px;
  }

  .form-actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 12px;
    width: 100%;
  }

  .send-form-button {
    margin: 0 auto;
    max-width: 320px;
    width: 100%;
  }
}

.order-info {
  &-list {
    margin-top: 16px;

    &__item {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.address-suggestion {
  padding: 6px 0;
  line-height: 110%;

  &-title {
    margin-bottom: 2px;
  }

  &-subtitle {
    font-size: 12px;
    color: var(--text-gray-color);
  }
}

.code-input-container {
  display: flex;
  gap: 8px;
}

.auth-hint, .payment-hint {
  margin-bottom: 16px;
  font-size: 12px;
  color: var(--text-gray-color);
}

.past-order-item {
  line-height: 115%;
  font-weight: 400;
  padding-bottom: 6px;

  &-title {
    font-size: 12px;
  }

  &-subtitle {
    opacity: .5;
    font-size: 10px;
  }
}

@media screen and (max-width: 1024px) {
  .sbor-form-page {
    .row {
      &.cols-2, &.cols-3 {
        grid-template-columns: 1fr;
        grid-gap: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .sbor-form-page {
    padding: 160px var(--hor-padding) 60px;

    &-container {
      grid-template-columns: 1fr;
      grid-gap: 24px;
    }

    &-title {
      margin-bottom: 32px;
    }
  }
}

@media screen and (max-width: 575px) {
  .sbor-form-page {
    padding: 140px var(--hor-padding) 40px;

    .form-sender {
      padding-bottom: 40px;
      border-bottom: 1px solid var(--border-color);
    }

    .form-receiver {
      padding-top: 40px;
    }

    .inline-calendar {
      width: 100%;

      &-container {
        overflow: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &-title {
      margin-bottom: 24px;
    }

    .form-column-title {
      font-size: 16px;
      margin-bottom: 16px;
    }

    .payment-buttons.form-radio-buttons {
      grid-template-columns: 1fr;
    }
  }
}
</style>
