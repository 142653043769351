<template>
  <div class="page profile-page">
    <h1 class="page-title">Заявки</h1>
    <div class="search-container">
      <el-input v-model="searchValue" placeholder="Поиск по таблице"/>
      <el-checkbox label="Только свои заявки" @change="getOrders"/>
    </div>
    <EasyDataTable
        :headers="headers"
        :items="orders.filter(order=>order.naim && order.city_from)"
        @click-row="onRowClick"
        empty-message="Нет заявок"
        :loading="loading"
        theme-color="var(--el-color-primary)"
        alternating
        rows-of-page-separator-message="из"
        rows-per-page-message="строк на странице:"
        :search-field="['code', 'date', 'city_from_name', 'city_to_name', 'sender_title', 'receiver_title', 'sender_inn', 'sender_name', 'receiver_name', 'receiver_inn']"
        :search-value="searchValue"
    >
      <template #item-status="{status}">
        {{ store.state.orderStatuses[status] }}
      </template>
      <template #item-date="{date}">
        {{ moment(date).locale('ru').format('D MMM YYYYг.') }}
      </template>
      <template #item-sender_title="{sender_title, sender_inn, sender_name}">
        {{ sender_inn ? sender_title : sender_name }}
      </template>
      <template #item-receiver_title="{receiver_title, receiver_inn, receiver_name}">
        {{ receiver_inn ? receiver_title : receiver_name }}
      </template>
      <template #item-actions="item">
        <el-button @click="createCopy(item)" size="small" style="height: 32px">Создать копию</el-button>
      </template>
    </EasyDataTable>
  </div>
</template>

<script setup>

import {computed, inject, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

const router = useRouter();
const api = inject('api');
const moment = inject('moment');
const store = useStore();
const orders = ref([]);
const loading = ref(false);
const headers = [
  {text: 'Номер заявки', value: 'code'},
  {text: 'Статус', value: 'status'},
  {text: 'Дата', value: 'date'},
  {text: 'Город отпр.', value: 'city_from_name'},
  {text: 'Отправитель', value: 'sender_title'},
  {text: 'Город пол.', value: 'city_to_name'},
  {text: 'Получатель', value: 'receiver_title'},
  {text: '', value: 'actions'},
];
const searchValue = ref('');
const getOrders = (isOwn = false) => {
  loading.value = true;
  api.request('order/get', {own_only: isOwn ? 1 : 0}).then(data => {
    loading.value = false;
    orders.value = data.response;
    orders.value = orders.value.map(order=>{
      order.city_from_name = cities.value.find(c => +c.id === +order.city_from)?.name;
      order.city_to_name = cities.value.find(c => +c.id === +order.city_to)?.name;
      return order;
    })
  }).catch(data => {
    loading.value = false;
  })
}

const onRowClick = (order) => {
  router.push({name: 'OrderFormPageUpdate', params: {id: order.id, token: order.token || null, city: currentCityCode.value}, query: {from_profile: 1}});
}

const createCopy = (item) =>{
  router.push({name: 'OrderFormPage', query: {copy: 1, item_id: item.id, from_profile: 1}, params: {city: currentCityCode.value}});
}
onMounted(() => {
  getOrders();
})

watch(() => store.state.user, () => {
  getOrders();
})

const cities = computed(() => store.state.cities)

const currentCityCode = computed(() => store.state.currentCityCode)
</script>

<style scoped lang="scss">
.search-container {
  margin-bottom: 16px;
  max-width: 400px;
}
</style>