<template>
  <div class="homepage">
    <div class="about-page-header">
      <div class="about-page-header-title">Центральная Транспортная Служба</div>
      <div class="about-page-header-actions">
        <router-link :to="{hash: '#calculator'}">
          <el-button class="el-button--white-border about-page-header-actions-button text-color-white">Рассчитать заказ
          </el-button>
        </router-link>
        <router-link :to="{name: 'TrackOrderPage'}">
          <el-button class="el-button--white-border about-page-header-actions-button text-color-white">Отследить груз
          </el-button>
        </router-link>
      </div>
      <router-link :to="{name: 'OrderFormPage'}" class="main-cta-button">
        <el-button class="about-page-header-actions-button" type="primary">Забор груза</el-button>
      </router-link>
    </div>
    <div class="calculator" id="calculator">
      <h1 class="title">Калькулятор</h1>
      <el-card>
        <el-form class="calculator_form" label-position="top" @input="onFormChange" :rules="rules"
                 :model="formDataCalc">
          <div class="row cols-2">
            <div class="col">
              <el-form-item label="Город отправления">
                <el-select
                    v-model="formDataCalc.city_from"
                    @change="calculate"
                    clearable
                    filterable
                    placeholder="Город отправления"
                    class="input-width-100">
                  <el-option v-for="city in store.state.cities.filter(c=>c.filial*1 === 1)" :key="city.id"
                             :label="city.name" :value="city.id"/>
                </el-select>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item label="Город назначения">
                <el-select
                    v-model="formDataCalc.city_to"
                    @change="calculate"
                    clearable
                    filterable
                    placeholder="Город назначения"
                    class="input-width-100">
                  <el-option v-for="city in store.state.cities.filter(c=>c.filial*1 === 1)" :key="city.id"
                             :label="city.name" :value="city.id"/>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="row cols-3">
            <div class="col"
                 style="display: grid; grid-template-columns: repeat(3,1fr); gap: 4px; align-items: flex-end">
              <el-form-item label="Габариты" prop="length">
                <el-input v-model="formDataCalc.length"
                          ref="lengthRef"
                          @input="onInputDimensions('length')"
                          class="dimensions-input" placeholder="ДД">
                  <template #append>м</template>
                </el-input>
              </el-form-item>
              <el-form-item prop="width">
                <el-input v-model="formDataCalc.width" class="dimensions-input" placeholder="ШШ"
                          ref="widthRef"
                          @input="onInputDimensions('width')">
                  <template #append>м</template>
                </el-input>
              </el-form-item>
              <el-form-item prop="height">
                <el-input v-model="formDataCalc.height" class="dimensions-input" placeholder="ВВ"
                          ref="heightRef"
                          @input="onInputDimensions('height')">
                  <template #append>м</template>
                </el-input>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item label="Вес" prop="ves">
                <el-input v-model="formDataCalc.ves"
                          @input="onInputDimensions('ves')" ref="vesRef">
                  <template #append>кг</template>
                </el-input>
              </el-form-item>
            </div>
            <div class="col">
              <el-form-item label="Объем" prop="obem">
                <el-input v-model="formDataCalc.obem"
                          @input="onInputDimensions('obem')" ref="obemRef">
                  <template #append>м<sup>3</sup></template>
                </el-input>
              </el-form-item>
            </div>
          </div>
          <div style="margin-bottom: 20px" v-if="calculatedData.items.tariff">Перевозка: {{
              globalMixins.methods.thousandSeparator(calculatedData.items.tariff?.sum || 0)
            }} ₽
          </div>

          <el-collapse accordion v-model="calculatorCollapse">
            <el-collapse-item name="expedition">
              <template #title>
                Экспедирование
                <template v-if="formDataCalc.source_distance || formDataCalc.destination_distance ||
                  calculatedData.items.expedition_from || calculatedData.items.expedition_to">
                  <font-awesome-icon icon="fa-solid fa-check" style="margin: 0 8px;"/>
                  <div
                      v-if="(calculatedData.items.expedition_from?.sum || 0) + (calculatedData.items.expedition_to?.sum || 0) > 0"
                      class="collapse-price">
                    + {{
                      globalMixins.methods.thousandSeparator((calculatedData.items.expedition_from?.sum || 0) + (calculatedData.items.expedition_to?.sum || 0))
                    }} ₽
                  </div>
                  <el-tooltip v-else
                              content="Точную стоимость уточняйте у менеджера"
                              placement="top-start">
                    <font-awesome-icon icon="fa-solid fa-circle-info"
                                       style="cursor: pointer; color: var(--text-gray-color)"/>
                  </el-tooltip>
                </template>
              </template>
              <div class="row cols-2">
                <div class="col">
                  <el-form-item>
                    <el-switch v-model="formDataCalc.expedition_from"
                               @change="calculate"
                               active-text="Экспедирование по городу отправления"/>
                    <el-input v-model="formDataCalc.source_distance"
                              :style="`opacity: ${formDataCalc.expedition_from ? 1 : 0};`"
                              :disabled="!formDataCalc.expedition_from"
                              v-maska="store.state.maskThousandSeparator"
                              placeholder="Удаленность от города">
                      <template #append>км</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="col">
                  <el-form-item>
                    <el-switch @change="calculate" v-model="formDataCalc.expedition_to"
                               active-text="Экспедирование по городу назначения"/>
                    <el-input v-model="formDataCalc.destination_distance" :disabled="!formDataCalc.expedition_to"
                              :style="`opacity: ${formDataCalc.expedition_to ? 1 : 0};`"
                              v-maska="store.state.maskThousandSeparator"
                              placeholder="Удаленность от города">
                      <template #append>км</template>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item name="insurance">
              <template #title>
                Страхование
                <template v-if="formDataCalc.osg || calculatedData.items.osg">
                  <font-awesome-icon icon="fa-solid fa-check" style="margin: 0 8px; "/>
                  <div v-if="(calculatedData.items.osg?.sum || 0) > 0" class="collapse-price">+ {{
                      globalMixins.methods.thousandSeparator(
                          calculatedData.items.osg?.sum || 0)
                    }} ₽
                  </div>
                  <el-tooltip v-else
                              content="Точную стоимость уточняйте у менеджера"
                              placement="top-start">
                    <font-awesome-icon icon="fa-solid fa-circle-info"
                                       style="cursor: pointer; color: var(--text-gray-color)"/>
                  </el-tooltip>
                </template>
              </template>
              <div class="row cols-2">
                <div class="col">
                  <el-form-item>
                    <el-switch v-model="formDataCalc.insurance" active-text="Страхование груза"/>
                    <el-input :style="`opacity: ${formDataCalc.insurance ? 1 : 0}`"
                              v-model="formDataCalc.osg"
                              placeholder="Стоимость груза"
                              v-maska="store.state.maskThousandSeparator">
                      <template #append>₽</template>
                    </el-input>
                  </el-form-item>
                </div>
              </div>
            </el-collapse-item>
            <el-collapse-item>
              <template #title>
                Дополнительно
                <template v-if="formDataCalc.bulky || formDataCalc.stretch_wrap || formDataCalc.special_conditions ||
formDataCalc.lathing || formDataCalc.bubble_wrap">
                  <font-awesome-icon icon="fa-solid fa-check" style="margin: 0 8px;"/>
                  <div v-if="additionalOptionsSum() > 0" class="collapse-price">+ {{
                      globalMixins.methods.thousandSeparator(
                          additionalOptionsSum())
                    }} ₽
                  </div>
                  <el-tooltip v-else
                              content="Точную стоимость уточняйте у менеджера"
                              placement="top-start">
                    <font-awesome-icon icon="fa-solid fa-circle-info"
                                       style="cursor: pointer; color: var(--text-gray-color)"/>
                  </el-tooltip>
                </template>
              </template>
              <el-form-item>
                <div class="switch-options">
                  <el-switch v-for="(option, key) in store.state.additional_options" :key="key" :active-text="option"
                             v-model="formDataCalc[key]" :active-value="1" :inactive-value="0" @change="calculate"/>
                </div>
              </el-form-item>
            </el-collapse-item>
          </el-collapse>
          <el-row :justify="'space-between'" class="form-price">
            <el-col :span="12" class="form-price-label-col">
              <div class="form-price-label">К оплате</div>
            </el-col>
            <el-col :span="12">
              <div class="form-price-sum">
                {{ globalMixins.methods.thousandSeparator(calculatedData.sum) }} ₽
              </div>
            </el-col>
          </el-row>
          <el-divider/>
          <div class="form-actions row cols-2">
            <div class="col">

            </div>
            <div class="col">
              <el-button class="form-actions-button" type="primary" @click="pushToOrderForm">Оформить заказ</el-button>
            </div>
          </div>
        </el-form>
      </el-card>
    </div>
    <el-divider/>
    <div class="about-page">
      <div class="about-page-content">
        <section class="description">
          <h1 class="about-page-content-title">О нас</h1>
          <p>
            Группа компаний «Центральная Транспортная Служба» (ЦТС) успешно оперирует на российском рынке грузоперевозок
            с
            1995 года. За это время был пройден долгий и сложный путь от службы по доставке книг до крупного холдинга,
            ставшего одним из ведущих операторов транспортно-логистической отрасли. На сегодняшний день мы можем
            предложить
            своим клиентам не только внутригородские транспортные услуги во всех крупных городах страны, но и низкие
            цены
            на
            грузоперевозки по России автомобильным и железнодорожным транспортом.
          </p>
          <p>
            Мы по праву гордимся своими продуманными бизнес-технологиями, безупречностью логистических цепочек и
            профессионализмом сотрудников! Помимо своего автопарка и наличия в собственности железнодорожных вагонов, мы
            располагаем накопительно-распределительными складскими комплексами, курьерской службой и разветвленной сетью
            пунктов выдачи заказов и грузов в различных российских регионах. Группа компаний ЦТС имеет собственные
            представительства и филиалы в таких городах России, как Москва, Санкт-Петербург, Великий Новгород,
            Иркутск, Казань, Калининград, Краснодар, Мурманск, Псков, Тверь, Уфа, Екатеринбург, Пермь, Барнаул,
            Севастополь,
            Сочи.

          </p>
        </section>
      </div>
      <section class="statistics">
        <div class="about-page-content">
          <div class="stats-items">
            <div class="stat-item" v-for="(stat, i) in store.state.stats" :key="i">
              <div class="stat-item-count">{{ stat.count }}</div>
              <div class="stat-item-label">{{ stat.label }}</div>
            </div>
          </div>
        </div>
      </section>
      <div class="about-page-content">
        <section class="services">
          <h1 class="about-page-content-title">Наши услуги</h1>
          <div class="services-list">
            <div class="service-item" v-for="(service, i) in store.state.services" :key="i">
              <div class="service-item-title">{{ service.title }}</div>
              <div class="service-item-description">{{ service.text }}</div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, reactive, onMounted, watch, inject, computed} from 'vue';
import {useRouter, useRoute} from 'vue-router'
import {useStore} from 'vuex';
import globalMixins from "@/globalMixins";

const store = useStore();
const router = useRouter();
const route = useRoute();
const api = inject('api');
let calculatedData = reactive({
  sum: 0, withoutNDS: 0, items: {}
});

const calculatorCollapse = ref('size');

const widthRef = ref();
const lengthRef = ref();
const heightRef = ref();
const vesRef = ref();
const obemRef = ref();
const mestRef = ref();

const sizesRefs = {width: widthRef, length: lengthRef, height: heightRef, ves: vesRef, obem: obemRef, mest: mestRef};

const dimensionsValidator = (rule, val, callback, param, errorMaxDimension) => {
  if (val > store.state.maxDimensionSizes[param] && sizesRefs[param]?.value) {
    sizesRefs[param].value.select();
    callback(new Error(`Не более ${store.state.maxDimensionSizes[param]} ${errorMaxDimension}`));
  } else callback();
}
const formDataCalc = reactive({
  city_from: null,
  city_to: null,
  ves: '',
  obem: '',
  width: '',
  height: '',
  length: '',
  expedition_from: false,
  source_distance: null,
  expedition_to: false,
  destination_distance: null,
  insurance: false,
  osg: null,
  special_conditions: 0,
  bulky: 0,
  lathing: 0,
  stretch_wrap: 0,
  bubble_wrap: 0,
  sizes: ''
});

const additionalParams = Object.keys(store.state.additional_options);

const rules = {
  width: [
    {trigger: 'blur', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'width', 'м')},
    {trigger: 'change', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'width', 'м')},
  ],
  length: [
    {trigger: 'blur', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'length', 'м')},
    {trigger: 'change', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'length', 'м')},
  ],
  height: [
    {trigger: 'blur', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'height', 'м')},
    {trigger: 'change', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'height', 'м')},
  ],
  ves: [
    {trigger: 'blur', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'ves', 'кг')},
    {trigger: 'change', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'ves', 'кг')},
  ],
  mest: [
    {trigger: 'blur', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'mest', 'мест')},
    {trigger: 'change', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'mest', 'мест')},
  ],
  obem: [
    {trigger: 'blur', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'obem', 'м3')},
    {trigger: 'change', validator: (rule, val, callback) => dimensionsValidator(rule, val, callback, 'obem', 'м3')},
  ]
}

const pushToOrderForm = () => {
  router.push({name: 'OrderFormPage', params: {}, query: {...formDataCalc}});
}

const calculate = () => {
  let formData = {...formDataCalc};
  let maskedValuesWithSpace = ['osg', 'destination_distance', 'source_distance', 'ves', 'obem', 'length', 'width', 'height'];
  maskedValuesWithSpace.forEach(val => {
    if (formDataCalc[val]) {
      formData[val] = formDataCalc[val].replaceAll(' ', '');
    }
  })
  if (formDataCalc.sizes) {
    let sizes = formDataCalc.sizes.split(' x ');
    formDataCalc.length = sizes[0] || null;
    formDataCalc.width = sizes[1] || null;
    formDataCalc.height = sizes[2] || null;
  }

  api.request('order/calculate', formData).then((data) => {
    Object.assign(calculatedData, data.response);
  });
}

const additionalOptionsSum = () => {
  let result = 0;
  additionalParams.forEach(param => {
    if (calculatedData.items[param]?.sum)
      result += calculatedData.items[param]?.sum;
  });
  return result;
}

const onFormChange = () => {
  calculate();
}
const onInputDimensions = (param) => {
  formDataCalc[param] = globalMixins.methods.checkIntOrFloatInput(formDataCalc[param], store.state.maxDimensionSizes[param]);
}

const currentCityCode = computed(() => {
  return store.state.currentCityCode;
})

const cities = computed(() => {
  return store.state.cities;
})
onMounted(async () => {
  await router.isReady();
  formDataCalc.city_from = +cities.value.find(c => c.code === currentCityCode.value)?.id || 1;
})

watch([() => cities.value, () => currentCityCode.value], () => {
  formDataCalc.city_from = +cities.value.find(c => c.code === currentCityCode.value)?.id || 1;
})
</script>

<style scoped lang="scss">
.home {
  background-image: linear-gradient(90deg, rgb(0 0 0) 0%, rgb(25 25 25) 50%, rgb(0 0 0) 100%);
  background-size: cover;
  height: 85vh;

  &-inner {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 220px var(--hor-padding) 0;

    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

    &-forms {
      width: 100%;
      max-width: var(--form-maxwidth);
      display: grid;
      justify-content: center;
      grid-template-columns: 1fr minmax(200px, 380px);
      gap: 32px;
    }
  }

  .title {
    color: #FFFFFF;
    margin-bottom: 40px;
    font-size: var(--headline-1);
    text-align: center;
  }

  .home-cta-buttons {
    display: flex;
    width: 100%;
    flex-direction: column;
    row-gap: 12px;
  }

  .calculate-button {
    width: 100%;
  }
}

.calculator {
  max-width: var(--form-maxwidth);
  margin: 0 auto;
  padding: 160px var(--hor-padding) 60px;

  .title {
    margin-bottom: 40px;
  }

  .form-title {
    margin-bottom: 8px;
    font-size: var(--headline-3);
  }

  .switch-options {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }

  .form-price {
    margin-top: 32px;

    &-label-col {
      display: flex;
      align-items: flex-end;
    }

    &-label {
      opacity: .4;
    }

    &-sum {
      font-size: var(--headline-2);
      text-align: end;
    }
  }

  .form-actions {
    margin-top: 32px;

    &-button {
      width: 100%;
    }
  }

  .collapse-price {
  }
}

@media screen and (max-width: 768px) {
  .home {
    height: auto;

    &-inner {
      padding-top: 180px;

      .title {
        margin-bottom: 24px;
      }

      &-forms {
        grid-template-columns: 1fr;
        max-width: 575px;
      }
    }

    .home-form {
      .home-input {
        margin-bottom: 16px;
      }
    }
  }
  .calculator {
    padding: 120px var(--hor-padding) 60px;

    .title {
      margin-bottom: 24px;
    }

    .switch-options {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
}

@media screen and (max-width: 575px) {
  .calculator {
    padding: 100px var(--hor-padding) 60px;

    .title {
      margin-bottom: 24px;
    }

    .row.form-actions {
      row-gap: 12px;
    }
  }
}

.about-page {
  &-header {
    padding: 0 var(--hor-padding);
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url("/public/images/main_bg.jpg");
    background-size: cover;

    &-title {
      margin-bottom: 40px;
      font-size: 48px;
      color: #FFF;
      text-align: center;
    }

    &-actions {
      max-width: 480px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 12px;

      &-button {
        width: 100%;
      }
    }

    .main-cta-button {
      max-width: 480px;
      width: 100%;
      margin-top: 20px;
    }
  }

  &-content {
    max-width: var(--desktop-maxwidth);
    padding: 100px var(--hor-padding);
    margin: 0 auto;

    &-title {
      font-size: 48px;
      margin-bottom: 40px;
    }

    p {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .statistics {
    background: url("/public/images/about_us_stats_block.jpeg") center no-repeat;
    background-size: cover;

    .stats-items {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 16px;

      .stat-item {
        color: #FFFFFF;
        text-align: center;

        &-count {
          margin-bottom: 8px;
          font-size: 52px;
        }

        &-label {
          font-size: 14px;
        }
      }
    }
  }

  .services-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;

    .service-item {
      &-title {
        margin-bottom: 24px;
        font-size: 24px;
      }

      &-description {
        font-size: 14px;
        line-height: 144%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about-page {
    &-header {
      &-title {
        margin-bottom: 32px;
        font-size: 42px;
      }
    }

    &-content {
      padding: 60px var(--hor-padding);

      &-title {
        font-size: 42px;
        margin-bottom: 32px;
      }
    }

    .statistics {
      .stats-items {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;

        .stat-item {
          &-count {
            margin-bottom: 8px;
            font-size: 46px;
          }
        }
      }
    }

    .services-list {
      display: flex;
      column-gap: 20px;
      width: calc(100% + var(--hor-padding) * 2);
      margin: 0 calc(0px - var(--hor-padding));
      padding: 0 var(--hor-padding);
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }

      .service-item {
        min-width: 310px;
        width: 310px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .about-page {
    &-header {
      &-title {
        margin-bottom: 24px;
        font-size: 36px;
      }

      &-actions {
        grid-template-columns: 1fr;
        grid-row-gap: 12px;
      }
    }

    &-content {
      padding: 40px var(--hor-padding);

      &-title {
        font-size: 36px;
        margin-bottom: 20px;
      }
    }

    .statistics {
      .stats-items {
        grid-template-columns: 1fr;
        grid-gap: 40px;

        .stat-item {
          &-count {
            margin-bottom: 8px;
            font-size: 46px;
          }
        }
      }
    }

    .services-list {
      display: flex;
      column-gap: 20px;
      width: calc(100% + var(--hor-padding) * 2);
      margin: 0 calc(0px - var(--hor-padding));
      padding: 0 var(--hor-padding);
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }

      .service-item {
        min-width: 310px;
        width: 310px;
      }
    }
  }
}
</style>
