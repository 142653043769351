export class Api {
    url = 'https://ctsp.cts-group.ru/web/';
    request(method, data) {
        const currentCompanyId = localStorage.getItem('currentCompanyId');
        let formData = JSON.stringify( currentCompanyId ? {...data, company: currentCompanyId} : data);
        return fetch(`${this.url}${method}`, {
            method: "POST",
            body: formData,
            credentials: "include",
        })
            .then(response => {
                if (!response.ok) throw new Error(response.statusText)
                // clearTimeout(id);
                return response.json();
            })
            .then(data => {
                if (data.success) return data;
                if (!['email'].includes(data.error?.type)) {
                    window.open_info_dialog(data.error?.text || data.error);
                }
                return Promise.reject(data);
            })
    }
}