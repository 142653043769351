<template>
  <div v-if="loading" class="page-loading">
    <div class="page-loading-title">Идет поиск груза...</div>
    <font-awesome-icon icon="fa-solid fa-magnifying-glass" beat size="2xl"
                       style="color: var(--el-text-color-secondary)"/>
  </div>
  <div class="page split">
    <div class="page-loading">
      <div class="page-loading-title">{{ error || 'Отслеживание груза' }}</div>
      <div class="page-loading-text">{{
          error ? 'Попробуйте указать другой номер накладной' : 'Укажите трек-номер груза'
        }}
      </div>
      <div class="page-error-content">
        <el-input v-model="trackNumber" @input="orderByTrackNumber.city_from=''" @change="trackNumber?getOrder():''"
                  placeholder="Номер накладной"/>
        <el-button @click="getOrder" type="border" class="track-order-button">
          <template #icon>
            <font-awesome-icon icon="fa-solid fa-magnifying-glass"/>
          </template>
          Отследить груз
        </el-button>
      </div>
    </div>
    <div v-if="orderByTrackNumber.city_from && trackNumber">
      <h1 class="page-title">Накладная № {{ trackNumber }}</h1>
      <el-card class="order-details-card">
        <template v-slot:header><span
            style="text-transform: capitalize">{{
            `${orderByTrackNumber.city_from} - ${orderByTrackNumber.city_to}`
          }}</span>
        </template>
        <div class="order-details-card-inner">
          <div class="order-details">
            <div class="order-details-title">Детали груза</div>
            <div class="order-details-list">
              <div class="order-details-list-item">{{ orderByTrackNumber.items }}
                {{ globalMixins.methods.getNoun(orderByTrackNumber.items, 'место', 'места', 'мест') }}
              </div>
              <div class="order-details-list-item">{{ orderByTrackNumber.weight }} кг</div>
              <div class="order-details-list-item">{{ orderByTrackNumber.volume }} м<sup>3</sup></div>
            </div>
          </div>
          <div class="order-steps">
            <div class="order-step" v-for="(step, i) in orderByTrackNumber.steps" :key="i"
                 :class="step.status === 'completed' ? 'completed' : ''">
              <div class="order-step-header">
                <div class="order-step-before">
                  <font-awesome-icon :icon="['fa-solid', step.status === 'completed' ? 'fa-check' : `${icons[i]}`]"/>
                </div>
                <div class="order-step-title">{{ step.title }}</div>
              </div>
              <div class="order-step-content">
                <div class="order-step-content-text">
                  {{ step.date ? moment(step.date).lang('ru').format('DD MMMM, YYYY') : '' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script setup>
import {inject, onMounted, reactive, ref} from 'vue';
import {useRoute, useRouter} from "vue-router";
import globalMixins from "@/globalMixins";

const router = useRouter();
const route = useRoute();

const api = inject('api');
const moment = inject('moment');

const loading = ref(false);
const error = ref('');
const trackNumber = ref('');
const withNumber = ref(false);
const orderByTrackNumber = reactive({city_from: '', city_to: '', steps: []});
const icons = ['', 'fa-truck', 'flag-checkered', 'fa-box-open'];
const getOrder = () => {
  loading.value = true;
  error.value = '';
  api.request('user/waybillInfo', {number: trackNumber.value}).then(data => {
    loading.value = false;
    withNumber.value = true;
    Object.assign(orderByTrackNumber, data.response);
    router.push({path: 'track', query: {number: trackNumber.value}})
  }).catch((data) => {
    error.value = data.error;
    loading.value = false;
    trackNumber.value = '';
  })
}

onMounted(async () => {
  await router.isReady();
  trackNumber.value = route.query.number || '';
  if (route.query.number) {
    getOrder(route.query.number);
    withNumber.value = true;
  }
})
</script>

<style scoped lang="scss">
.page-loading {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &-title {
    margin-bottom: 24px;
    font-size: var(--headline-2);
    font-weight: 500;
  }

  &-text {
    margin-bottom: 40px;
  }

  .track-order-button {
    margin-top: 12px;
    width: 100%;
  }
}

.page-title {
  margin-bottom: 40px;
}

.order-details-card {
  margin-bottom: 40px;
  max-width: 640px;

  &-inner {
    display: flex;
    gap: 64px;
  }
}

.order-details {
  &-title {
    margin-bottom: 16px;
    font-size: var(--headline-3);
    font-weight: 500;
  }

  &-list {
    &-item {
      padding: 12px 0;
      border-bottom: 1px solid var(--border-color);
    }
  }
}

.order-steps {
  position: relative;

  &::before {
    position: absolute;
    left: 20px;
    content: ' ';
    width: 2px;
    background: #e4e7ed;
    height: 100%;
  }
}

.order-step {
  padding-top: 24px;
  z-index: 1;
  position: relative;

  &.completed {
    .order-step-before {
      background: var(--green-light-color);
    }

    .order-step-title {
      opacity: 1;
    }
  }

  &:first-child {
    padding-top: 0;
  }

  &-header {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  &-before {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: #e4e7ed;
  }

  &-title {
    opacity: .4;
  }

  &-content {
    padding-left: 52px;

    &-text {
      color: var(--text-gray-color);
      font-size: 14px;
    }
  }
}

.page.split {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 20px
}

@media screen and (max-width: 900px) {
  .page.split {
    flex-direction: column;
    :first-child {
      min-height: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .page-title {
    margin-bottom: 32px;
  }
  .order-details-card {
    max-width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .page-title {
    margin-bottom: 24px;
  }
  .order-details-card-inner {
    flex-direction: column;
    gap: 32px;
  }
}
</style>
