import {createApp} from 'vue'
import App from './App.vue'
import router from "@/router";
import store from "@/store";
// import './assets/vars.scss';
import ElementPlus from 'element-plus'
import ru from 'element-plus/dist/locale/ru';
import 'element-plus/dist/index.css'
import "@/assets/styles.scss";
import Vue3Lottie from 'vue3-lottie'
import Maska from "maska";
import {createYmaps} from 'vue-yandex-maps';
import moment from "moment";
import dayjs from 'dayjs'

dayjs.Ls.ru ??= {};
dayjs.Ls.ru.weekStart = 1

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';


/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {} from '@fortawesome/free-regular-svg-icons'
import {
  faBars,
  faEnvelope,
  faPhone,
  faTruck,
  faLocationDot,
  faCalculator,
  faRubleSign,
  faCompass,
  faFolderOpen,
  faMap,
  faUser,
  faCheck,
  faCube,
  faCalendarDays,
  faRightFromBracket,
  faChevronDown,
  faUserCircle,
  faMagnifyingGlass,
  faFlagCheckered,
  faBoxOpen,
  faTrashCan,
  faList,
  faGear, faUsers, faArrowLeft, faDownload
} from '@fortawesome/free-solid-svg-icons'
import {faCircleInfo} from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import {Api} from "@/api/api";

/* add icons to the library */
library.add(
  faBars,
  faEnvelope,
  faPhone, faTruck,
  faLocationDot,
  faCalculator,
  faRubleSign,
  faCompass,
  faFolderOpen,
  faCircleInfo,
  faMap,
  faUser,
  faCheck,
  faCube,
  faCalendarDays,
  faRightFromBracket,
  faChevronDown,
  faUserCircle,
  faMagnifyingGlass,
  faFlagCheckered,
  faBoxOpen,
  faTrashCan,
  faList,
  faGear,
  faUsers,
  faArrowLeft,
  faDownload
)

const api = new Api();

const app = createApp(App);
app
  .use(router)
  .use(store)
  .use(ElementPlus, {
    locale: ru
  })
  .use(Vue3Lottie)
  .use(Maska)
  .use(createYmaps({
    apikey: 'd70bbdd2-c884-42e6-b266-500d62d4a03f',
    lang: 'ru_RU'
  }))
  .component('font-awesome-icon', FontAwesomeIcon)
  .component('EasyDataTable', Vue3EasyDataTable)

  .provide('api', api)
  .provide('moment', moment)
  .mount('#app')