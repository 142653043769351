import {createStore} from 'vuex'

export default createStore({
    state() {
        return {
            maskThousandSeparator: ['#', '##', '###', '# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###'],
            mask_inn: ['##########', '############'],
            mask_bik: ['#########'],
            phone_mask: '+7 (###) ### ## ##',
            genders: ['Не выбрано', 'Мужской', 'Женский'],
            userGetLoading: false,
            cities: [],
            citiesWithTariff: [],
            currentCityCode: 'moskva',
            terminals: [],
            user: {},
            additional_options: {
                bulky: 'Наличие нестандартных мест',
                stretch_wrap: 'Упаковка в стреч пленку',
                special_conditions: 'Требуются особые условия перевозки',
                lathing: 'Обрешетка',
                bubble_wrap: 'Упаковка в пузырчатую пленку'
            },
            maxDimensionSizes: {
                width: 2.4,
                height: 2.4,
                length: 12.9,
                obem: 79.9,
                ves: 19999,
                mest: 999,
            },
            orderStatuses: ['Черновик', 'Отправлено', 'Обработано'],
            categories: [
                {
                    "value": "Авто-мото-вело техника",
                    "children": [
                        {
                            "value": "Автоаксессуары",
                            "needWrapping": false
                        },
                        {
                            "value": "Автозапчасти",
                            "needWrapping": true
                        },
                        {
                            "value": "Автозапчасти кузовные",
                            "needWrapping": true
                        },
                        {
                            "value": "Автозапчасти хрупкие",
                            "needWrapping": true
                        },
                        {
                            "value": "Автомобильное стекло",
                            "needWrapping": true
                        },
                        {
                            "value": "Автомобильный двигатель",
                            "needWrapping": true
                        },
                        {
                            "value": "Автохимия",
                            "needWrapping": true
                        },
                        {
                            "value": "Аккумуляторы незаряженные",
                            "needWrapping": true
                        },
                        {
                            "value": "Велосипеды",
                            "needWrapping": true
                        },
                        {
                            "value": "Масла автомобильные",
                            "needWrapping": false
                        },
                        {
                            "value": "Мототехника (мотозапчасти)",
                            "needWrapping": true
                        }
                    ]
                },
                {
                    "value": "Бытовая техника и электроника",
                    "children": [
                        {
                            "value": "Бытовая техника без зав. упаковки",
                            "needWrapping": true
                        },
                        {
                            "value": "Бытовая техника в зав. упаковке",
                            "needWrapping": true
                        },
                        {
                            "value": "Компьютеры, планшеты, смартфоны",
                            "needWrapping": true
                        }
                    ]
                },
                {
                    "value": "Игрушки",
                },
                {
                    "value": "Канцелярские товары",
                    "children": [
                        {

                            "value": "Канцелярские товары",
                            "needWrapping": false
                        }
                    ]
                },
                {
                    "value": "Личные вещи",
                },
                {
                    "value": "Мебель и предметы украшения интерьера",
                    "children": [
                        {
                            "value": "Двери",
                            "needWrapping": true
                        },
                        {
                            "value": "Мебель корпусная",
                            "needWrapping": true
                        },
                        {
                            "value": "Мебель металлическая",
                            "needWrapping": true
                        },
                        {
                            "value": "Мебель мягкая",
                            "needWrapping": true
                        },
                        {
                            "value": "Мебель пластиковая",
                            "needWrapping": true
                        },
                        {
                            "value": "Мебельная фурнитура",
                            "needWrapping": true
                        }
                    ]
                },
                {
                    "value": "Медицинские товары",
                    "children": [
                        {
                            "value": "Медицинские товары",
                            "needWrapping": true
                        },
                        {
                            "value": "Медицинское оборудование",
                            "needWrapping": true
                        }
                    ]
                },
                {
                    "value": "Музыкальные товары",
                    "children": [
                        {
                            "value": "Музыкальные инструменты",
                            "needWrapping": true
                        }
                    ]
                },
                {
                    "value": "Оборудование",
                    "children": [
                        {
                            "value": "Оборудование",
                            "needWrapping": true
                        },
                        {
                            "value": "Промышленное оборудование",
                            "needWrapping": true
                        }
                    ]
                },
                {
                    "value": "Обувь",
                    "children": [
                        {
                            "value": "Обувь"
                        }
                    ]
                },
                {
                    "value": "Одежда",
                    "children": [
                        {
                            "value": "Одежда"
                        }
                    ]
                },
                {
                    "value": "Оргтехника",
                    "children": [
                        {
                            "value": "Орг. техника в зав. упаковке",
                            "needWrapping": true
                        },
                        {
                            "value": "Орг.техника без зав. упаковки",
                            "needWrapping": true
                        }
                    ]
                },
                {
                    "value": "Печатная (полиграфическая) продукция",
                    "children": [
                        {
                            "value": "Печатная продукция",
                            "needWrapping": false
                        }
                    ]
                },
                {
                    "value": "Посуда",
                    "children": [
                        {
                            "value": "Посуда",
                            "needWrapping": true
                        }
                    ]
                },
                {

                    "value": "Продукты питания",
                    "children": [
                        {

                            "value": "Пищевые добавки",
                            "needWrapping": false
                        }
                    ]
                },
                {

                    "value": "Промышленные товары",
                    "children": [
                        {

                            "value": "Сырье промышленное"
                        }
                    ]
                },
                {

                    "value": "Сборный груз",
                    "children": [
                        {

                            "value": "Запчасти",
                            "needWrapping": true
                        },
                        {

                            "value": "Сборный груз"
                        }
                    ]
                },
                {

                    "value": "Спортивные товары",
                    "children": [
                        {

                            "value": "Спортивные тренажеры",
                            "needWrapping": true
                        }
                    ]
                },
                {

                    "value": "Стекло",
                    "children": [
                        {

                            "value": "Стекло листовое",
                            "needWrapping": true
                        }
                    ]
                },
                {

                    "value": "Строительные товары",
                    "children": [
                        {

                            "value": "Кафель",
                            "needWrapping": true
                        },
                        {

                            "value": "Керамика",
                            "needWrapping": true
                        },
                        {

                            "value": "Лакокрасочные материалы",
                            "needWrapping": true
                        },
                        {

                            "value": "Профиль металлический",
                            "needWrapping": true
                        },
                        {

                            "value": "Профиль пластиковый",
                            "needWrapping": true
                        },
                        {

                            "value": "Стеклопакеты",
                            "needWrapping": true
                        },
                        {

                            "value": "Строительные материалы",
                            "needWrapping": true
                        },
                        {

                            "value": "Труба металлическая",
                            "needWrapping": true
                        },
                        {

                            "value": "Труба пластиковая",
                            "needWrapping": true
                        }
                    ]
                },
                {

                    "value": "Сувенирная продукция",
                    "children": [
                        {

                            "value": "Сувенирная продукция",
                            "needWrapping": false
                        }
                    ]
                },
                {

                    "value": "Табачная продукция",
                    "children": [
                        {

                            "value": "Табачная продукция",
                            "needWrapping": false
                        }
                    ]
                },
                {

                    "value": "Текстильная продукция",
                    "children": [
                        {

                            "value": "Текстиль"
                        }
                    ]
                },
                {

                    "value": "Товары народного потребления",
                    "children": [
                        {

                            "value": "Товары народного потребления (ТНП)"
                        }
                    ]
                },
                {

                    "value": "Только ВЭД",
                    "children": [
                        {

                            "value": "Аксессуары для мобильных телефонов",
                            "needWrapping": false
                        },
                        {

                            "value": "Аудиотехника новая/бу",
                            "needWrapping": false
                        },
                        {

                            "value": "БАДы",
                            "needWrapping": false
                        },
                        {

                            "value": "Бытовая химия/чистящие/моющие средства",
                            "needWrapping": false
                        },
                        {

                            "value": "Генераторы разной массы",
                            "needWrapping": false
                        },
                        {

                            "value": "Герметики",
                            "needWrapping": false
                        },
                        {

                            "value": "Кухонная утварь",
                            "needWrapping": false
                        },
                        {

                            "value": "Маникюрные/педикюрные принадлежности",
                            "needWrapping": false
                        },
                        {

                            "value": "Моторное масло",
                            "needWrapping": false
                        },
                        {

                            "value": "Музыкальное оборудование (не инструменты)",
                            "needWrapping": false
                        },
                        {

                            "value": "Насосы любого давления",
                            "needWrapping": false
                        },
                        {

                            "value": "Органайзеры",
                            "needWrapping": false
                        },
                        {

                            "value": "Парикмахерские инструменты",
                            "needWrapping": false
                        },
                        {

                            "value": "Пластиковые изделия (не тара)",
                            "needWrapping": false
                        },
                        {

                            "value": "Продукты питания (нескоропортящиеся)",
                            "needWrapping": false
                        },
                        {

                            "value": "Рюкзаки/сумки",
                            "needWrapping": false
                        },
                        {

                            "value": "Спортивное питание",
                            "needWrapping": false
                        },
                        {

                            "value": "Спортивный инвентарь",
                            "needWrapping": false
                        },
                        {

                            "value": "Станки разного размера",
                            "needWrapping": false
                        },
                        {

                            "value": "Стеклянная тара любого объема",
                            "needWrapping": false
                        },
                        {

                            "value": "Чай/кофе",
                            "needWrapping": false
                        },
                        {

                            "value": "Часы",
                            "needWrapping": false
                        },
                        {

                            "value": "Шины",
                            "needWrapping": false
                        },
                        {

                            "value": "Электроника новая, б/у",
                            "needWrapping": false
                        },
                        {

                            "value": "Электротехника новая, б/у",
                            "needWrapping": false
                        }
                    ]
                },
                {
                    "value": "Упаковочная продукция",
                    "children": [
                        {

                            "value": "Пластиковая тара любого V",
                            "needWrapping": true
                        },
                        {

                            "value": "Упаковка"
                        },
                        {

                            "value": "Ящик "
                        }
                    ]
                },
                {
                    "value": "Хозяйственные товары",
                    "children": [
                        {

                            "value": "Огнетушители",
                            "needWrapping": true
                        },
                        {

                            "value": "Садово-огородный инвентарь",
                            "needWrapping": true
                        },
                        {

                            "value": "Хоз.товары из пластика",
                            "needWrapping": true
                        }
                    ]
                },
                {
                    "value": "Электрооборудование",
                    "children": [
                        {

                            "value": "Кабель электрический",
                            "needWrapping": false
                        }
                    ]
                },
                {
                    "value": "Выставочное оборудование",
                    "needWrapping": true
                },
                {

                    "value": "Зоотовары"
                },
                {

                    "value": "Изделия из камня",
                    "needWrapping": true
                },
                {

                    "value": "Инструменты",
                    "needWrapping": false
                },
                {

                    "value": "Конверты",
                    "needWrapping": false
                },
                {

                    "value": "Косметическая продукция",
                    "needWrapping": true
                },
                {

                    "value": "Крепеж",
                    "needWrapping": false
                },
                {

                    "value": "Матрас",
                    "needWrapping": true
                },
                {

                    "value": "Металл.емкости любого V",
                    "needWrapping": true
                },
                {

                    "value": "Металлопрокат (изделия из металла)",
                    "needWrapping": false
                },
                {

                    "value": "Пластик листовой",
                    "needWrapping": true
                },
                {

                    "value": "Полиграфическая продукция",
                    "needWrapping": false
                },
                {

                    "value": "Резинотехн. изделия (РТИ)"
                },
                {

                    "value": "Рекламная продукция"
                },
                {

                    "value": "Сантехника",
                    "needWrapping": true
                },
                {

                    "value": "Сварочная техника и материалы",
                    "needWrapping": true
                },
                {

                    "value": "Столешницы",
                    "needWrapping": true
                },
                {

                    "value": "Трубопроводная арматура",
                    "needWrapping": false
                },
                {

                    "value": "Хоз.товары",
                    "needWrapping": false
                }
            ],
            wrap_options: {
                stretch_wrap: 'Упаковка в стреч пленку',
                lathing: 'Обрешетка',
                bubble_wrap: 'Упаковка в пузырчатую пленку'
            },
            stats: [
                {count: '700+', label: 'городов'},
                {count: '30', label: 'филиалов'},
                {count: '2 000+', label: 'постоянных клиентов'},
                {count: '30+', label: 'рейсов ежедневно'},
            ],
            services: [
                {
                    title: 'Сборные грузы',
                    text: 'Мы предлагаем полный комплекс услуг по приему, хранению, формированию и транспортировке мелких сборных грузов по всей России, как автомобильным, так и железнодорожным транспортом. Ниже вы можете убедиться в привлекательности и справедливости наших тарифов на перевозку сборных грузов. В эти тарифы входит не только транспортировка грузов, но и их хранение, погрузо-разгрузочные работы, целый ряд мер по обеспечению безопасности и сохранности.'
                },
                {
                    title: 'Автомобильные перевозки',
                    text: 'Мы берем на себя не только грузоперевозки в рамках отдельно взятого города, но и междугородние перевозки по России. Имея собственный автопарк, а также обладая разветвленной сетью филиалов в разных регионах Российской Федерации, группа компаний ЦТС поможет своим клиентам осуществить грузоперевозки недорого, выбрав оптимальный вариант, в зависимости от характера груза, его объема, расстояний перемещения и индивидуальных пожеланий заказчика.'
                },
                {
                    title: 'Железнодорожные перевозки',
                    text: 'Такой способ доставки грузов считается на сегодняшний день наиболее экономичным, надежным и безопасным, позволяющим, к тому же, обслуживать удаленные и труднодоступные пункты назначения. Железнодорожные перевозки не только позволяют перемещать большое количество грузов на значительные расстояния, но и, благодаря четким и согласованным графикам движения делают их более прогнозируемыми и подконтрольными в сравнении с автоперевозками. Собственный вагонный парк компании позволяет обрабатывать любые виды грузов.'
                },
            ],
            workerStatuses: [],

        }
    },
    mutations: {
        setCities(state, value) {
            state.cities = value;
        },
        setCitiesWithTariff(state, value) {
            state.citiesWithTariff = value;
        },
        setCurrentCityCode(state, value) {
            state.currentCityCode = value;
        },
        setTerminals(state, value) {
            state.terminals = value.map(terminal=>{
                terminal.id = terminal.id*1;
                return terminal;
            });
        },
        setUser(state, value) {
            console.log('setUser',value)
            state.user = value;
        },
        setUserGetLoading(state, value) {
            state.userGetLoading = value;
        },
        setWorkerStatuses(state, value) {
            state.workerStatuses = value;
        }
    },
    getters: {
        getUser(state) {
            return state.user;
        }
    }
})
