<template>
  <div class="page addresses-page">
    <h1 class="title">Филиалы</h1>
    <el-input v-model="querySearch" @input="onTerminalSearch" clearable
              placeholder="Поиск по городу, названию терминала, контактам"
              style="margin-bottom: 16px"/>
    <div class="addresses-page-inner">
      <div class="addresses-list">
        <template v-if="searchedTerminals.length">
          <div class="addresses-list-item" v-for="item in
        searchedTerminals.sort((a, b)=>a.city === cities.find(c=>c.code === currentCityCode)?.name
        && b.city !== cities.find(c=>c.code === currentCityCode)?.name ?
         -1 : a.city !== cities.find(c=>c.code === currentCityCode)?.name
         && b.city === cities.find(c=>c.code === currentCityCode)?.name ? 1 : 0)" :key="item.id"
               @click="onAddressClick(item.id)"
               :class="{'addresses-list-item__selected': selectedTerminalId === item.id}">
            <div class="addresses-list-item-header">
              <font-awesome-icon icon="fa-solid fa-map"
                                 style="margin-right: 8px; opacity: .5; font-size: 12px; padding-top: 5px"/>
              <div class="addresses-list-item__title">{{ [item.city, item.name].join(', ') }}</div>
            </div>
            <div class="addresses-list-item__subtitle">{{ item.address }}</div>
            <div class="addresses-list-item__worktime">{{ item.clock }}</div>
          </div>
        </template>
        <template v-else>
          <div class="no-found-terminal">
            Терминал не найден. Попробуйте изменить параметры поиска
          </div>
        </template>
      </div>
      <el-card class="addresses-map" style="height: fit-content">
        <template v-if="selectedTerminal?.id">
          <div class="contacts">
            <div class="contacts-header">
              <div class="contacts-title">{{ [selectedTerminal?.city, selectedTerminal?.name].join(', ') }}</div>
              <div class="addresses-list-item__subtitle">{{ selectedTerminal?.address }}</div>
              <div class="addresses-list-item__worktime">{{ selectedTerminal?.clock }}</div>
            </div>
            <a v-for="tel in selectedTerminal?.telephones" :key="tel" :href="`tel: ${tel}`">
              <font-awesome-icon icon="fa-solid fa-phone"
                                 style="margin-right: 8px; opacity: .5"/>
              {{ tel }}
            </a>
            <a v-if="selectedTerminal?.email" :href="`mailto: ${selectedTerminal.email}`">
              <font-awesome-icon icon="fa-solid fa-envelope"
                                 style="margin-right: 8px; opacity: .5"/>
              {{ selectedTerminal.email }}
            </a>
          </div>
          <div v-if="selectedTerminal?.map_yandex"
               class="address-list-item-html" v-html="selectedTerminal.map_yandex"/>
          <div class="address-list-item-actions">
            <a v-if="selectedTerminal?.download"
               :href="selectedTerminal?.download" target="_blank" download style="width: fit-content">
              <el-button>Скачать схему</el-button>
            </a>
          </div>
        </template>
        <template v-else>
          <div class="no-found-terminal">
            Терминал не найден, либо отсутствует информация по терминалу.
          </div>
        </template>
      </el-card>
    </div>
  </div>
</template>

<script setup>
import {useStore} from "vuex";
import {shallowRef, ref, computed, watch, onMounted} from 'vue';
import {YandexMap, YandexMapDefaultSchemeLayer, YandexMapMarker, YandexMapDefaultFeaturesLayer} from 'vue-yandex-maps';

const store = useStore();
let selectedTerminalId = ref('2');

const querySearch = ref('');
const onTerminalSearch = (v) => {
  v = v.toLowerCase();
  searchedTerminals.value = allTerminals.value.filter(t => t.city.toLowerCase().includes(v)
      || t.name.toLowerCase().includes(v) ||
      t.address.toLowerCase().includes(v) || t.email.toLowerCase().includes(v) || t.phones?.filter(phone => phone.includes(v)).length);
}
const onAddressClick = (terminal) => {
  selectedTerminalId.value = terminal;
}

const searchedTerminals = ref([]);
const allTerminals = ref([]);
const selectedTerminal = computed(() => {
  return allTerminals.value.find(terminal => terminal.id === selectedTerminalId.value);
})

const currentCityCode = computed(() => {
  return store.state.currentCityCode;
})

const cities = computed(() => {
  return store.state.cities;
})

const setTerminals = () =>{
  searchedTerminals.value = store.state.terminals;
  allTerminals.value = store.state.terminals;
  selectedTerminalId.value = allTerminals.value.find(t=>t.city === cities.value.find(c=>c.code === currentCityCode.value)?.name)?.id;
}

onMounted(()=>{
  setTerminals();
})

watch([() => store.state.terminals, ()=>store.state.currentCityCode], () => {
  setTerminals();
})
</script>

<style scoped lang="scss">
.addresses-page {
  .title {
    margin-bottom: 40px;
  }

  &-inner {
    display: grid;
    grid-template-columns: 320px 1fr;
    grid-gap: 12px;
  }

  .no-found-terminal {
    text-align: center;
    padding: 40px 16px;
  }
  .addresses-list {
    max-height: 560px;
    overflow: auto;
    -webkit-box-shadow: 0px -8px 20px -8px rgba(0, 0, 0, 0.1);
    box-shadow: 0px -8px 20px -8px rgba(0, 0, 0, 0.1);
    &-item {
      padding: 16px 20px;
      border-bottom: 1px solid var(--border-color);
      cursor: pointer;

      &__selected {
        background: var(--border-color);
      }

      &:hover {
        background: var(--border-color);
      }

      &-header {
        display: flex;
        align-items: flex-start;
        margin-bottom: 4px;
      }

      &__title {
        font-size: 16px;
      }

      &__subtitle {
        margin-bottom: 12px;
        font-size: 12px;
      }

      &__worktime {
        font-size: 12px;
        opacity: .5;
      }
    }
  }

  .addresses-map {
    .contacts {
      padding: 0 var(--el-card-padding);
      margin-bottom: 40px;

      &-header {
        margin-bottom: 16px;
      }

      &-title {
        font-size: 18px;
        margin-bottom: 4px;
      }

      a {
        font-size: 14px;
        display: block;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .address-list-item-actions {
      padding: var(--el-card-padding);
    }
  }
}

@media screen and (max-width: 1024px) {
  .addresses-page {
    .title {
      margin-bottom: 32px;
    }

    &-inner {
      height: auto;
      grid-template-columns: 1fr;
    }

    .addresses-list {
      max-height: 333px;
      height: 100%;
    }

    .addresses-map {
      height: 400px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 575px) {
  .addresses-page {
    .title {
      margin-bottom: 24px;
    }

    .addresses-list {
      &-item {
        padding: 8px 16px;

        &-header {
          margin-bottom: 8px;
        }

        &__title {
          font-size: 18px;
        }

        &__text {
          font-size: 14px;
        }
      }
    }
  }
}
</style>