<template>
  <el-dialog v-model="dialogVisible" :title="title" width="320px" :align-center="true">
    <div v-if="subtitle" class="info-subtitle">{{subtitle}}</div>
    <a class="info-link" v-if="link" :href="link.url">{{link.label}}</a>
    <div class="confirm-dialog-actions">
      <el-button @click="onOkClick" style="width: 100%;">Ок</el-button>
    </div>
  </el-dialog>
</template>

<script setup>
import {defineExpose, ref, defineEmits} from "vue";

const emits = defineEmits(['on-ok-click']);
const dialogVisible = ref(false)
const title = ref('');
const subtitle = ref('');
const link = ref(null);
const open = (_title, _subtitle, _link = null) => {
  dialogVisible.value = true;
  title.value = _title;
  subtitle.value = _subtitle;
  link.value = _link;
}

const onOkClick = () =>{
  emits('on-ok-click');
  close();
}
const close = () =>{
  dialogVisible.value = false;
}

defineExpose({
  open,
  close,
})
</script>

<style scoped>
.info-subtitle {
  text-align: center;
}
.info-link {
  display: block;
  margin: 16px;
  text-align: center;
  text-decoration: underline;
}
.confirm-dialog-actions {
  margin-top: 40px;
}
</style>