<template>
  <div class="page profile-header-container">

    <div class="profile-page-header">
      <div class="profile-page-header-title">
        <font-awesome-icon icon="fa-solid fa-user-circle" style="margin-right: 4px;"/>
        {{ `${user.surname} ${user.name ? `${user.name[0]}.` : ''} ${user.otch ? `${user.otch[0]}.` : ''}` }}
      </div>
      <div class="profile-page-header-menu" style="flex-grow: 1">
        <router-link v-for="(menuItem, i) in menu" :to="{name: menuItem.pageName, params: {city: currentCityCode}}"
                     :key="i">
          <font-awesome-icon :icon="`fa-solid fa-${menuItem.icon}`" style="margin-right: 4px;"/>
          {{ menuItem.label }}
        </router-link>
      </div>
      <el-dropdown hide-on-click trigger="click" class="profile-dropdown-menu">
        <el-button>
          <template #icon>
            <font-awesome-icon icon="fa-solid fa-bars"/>
          </template>
          Меню профиля
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-for="(menuItem, i) in menu" :key="i">
              <router-link :to="{name: menuItem.pageName, params: {city: currentCityCode}}">
                <font-awesome-icon :icon="`fa-solid fa-${menuItem.icon}`" style="margin-right: 4px;"/>
                {{ menuItem.label }}
              </router-link>
            </el-dropdown-item>
            <el-divider style="margin: 0"/>
            <div class="company-list-item" v-for="company in user.companies" :key="company.id">
              <el-dropdown-item @click="setCurrentCompany(company.id)" style="flex-grow: 1">
                <el-button text>{{ company.name }}</el-button>
              </el-dropdown-item>
              <el-button text @click.prevent="openConfirmRemoveCompany(company.id)">
                <font-awesome-icon icon="fa-solid fa-trash-can"/>
              </el-button>
            </div>
            <el-dropdown-item>
              <el-button type="primary" @click="addCompany" style="width: 100%;">+ Добавить команию</el-button>
            </el-dropdown-item>
            <el-divider/>
            <el-dropdown-item>
              <el-button style="width: 100%" @click="openSignOutDialog">
                <template #icon>
                  <font-awesome-icon icon="fa-solid fa-right-from-bracket"/>
                </template>
                Выйти
              </el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <div class="profile-page-header-menu">
        <!--      <router-link :to="{name: 'ProfileBalancePage', params: {city: currentCityCode}}">
                <el-button text>Баланс: {{ globalMixins.methods.thousandSeparator(-23450) }} ₽</el-button>
              </router-link>-->
        <el-dropdown v-if="user.currentCompany" trigger="click">
          <el-button text>
            {{ user.currentCompany.name }}
            <font-awesome-icon icon="fa-solid fa-chevron-down" style="margin-left: 8px;"/>
          </el-button>
          <template #dropdown>
            <div class="company-list-item" v-for="company in user.companies" :key="company.id">
              <el-dropdown-item @click="setCurrentCompany(company.id)" style="flex-grow: 1">
                <el-button text>{{ company.name }}</el-button>
              </el-dropdown-item>
              <el-button text @click.prevent="openConfirmRemoveCompany(company.id)">
                <font-awesome-icon icon="fa-solid fa-trash-can"/>
              </el-button>
            </div>
            <el-divider style="margin: 0"/>
            <el-dropdown-item @click="addCompany">
              <el-button text>+ Добавить команию</el-button>
            </el-dropdown-item>
          </template>
        </el-dropdown>
        <el-button v-else @click="addCompany" text>+ Добавить команию</el-button>

        <!--        <div class="user-name">-->
        <!--          -->
        <!--        </div>-->
        <el-button text @click="openSignOutDialog">
          <template #icon>
            <font-awesome-icon icon="fa-solid fa-right-from-bracket" style="font-size: 18px"/>
          </template>
        </el-button>
      </div>
    </div>
    <BackLink v-if="['ShipmentItemPage', 'OrderFormPage', 'OrderFormPageUpdate', 'OrderPage'].includes(route.name)" text="Назад"/>
  </div>
  <ConfirmDialog ref="confirm_signout_dialog" title="Вы уверены, что хотите выйти?" decline-button="Нет"
                 confirm-button="Да, выйти"
                 @confirm="signOut()"/>
  <ConfirmDialog ref="confirm_remove_company" title="Вы уверены, что хотите удалить компанию?" decline-button="Нет"
                 confirm-button="Да, удалить"
                 @confirm="removeCompany"/>
  <AddCompanyDialog ref="addCompanyDialog" @on-send-form="getInfo"/>
</template>

<script setup>

import {computed, inject, ref, defineEmits, onMounted} from "vue";
import globalMixins from "@/globalMixins";
import ConfirmDialog from "@/dialogs/ConfirmDialog";

import {useStore} from "vuex";
import AddCompanyDialog from "@/dialogs/AddCompanyDialog";
import BackLink from "@/components/BackLink";
import {useRoute, useRouter} from "vue-router";

const route = useRoute();
const router = useRouter();
const api = inject('api');
const store = useStore();
const addCompanyDialog = ref();
const confirm_remove_company = ref();
const user = computed(() => {
  return store.getters.getUser;
})

const menu = [
  {label: 'Заявки', pageName: 'OrdersPage', icon: 'list'},
  {label: 'Грузы', pageName: 'ShipmentsPage', icon: 'truck'},
  {label: 'Сотрудники', pageName: 'WorkersPage', icon: 'users'},
  {label: 'Настройки', pageName: 'ProfilePage', icon: 'gear'},
]

const signOut = () => {
  confirm_signout_dialog.value.close();
  api.request('auth/logout').then(() => {
    localStorage.clear();
    store.commit('setUser', {});
  })
}

const confirm_signout_dialog = ref();

const openSignOutDialog = () => {
  confirm_signout_dialog.value.open();
}

const addCompany = () => {
  addCompanyDialog.value.open()
}

const setCurrentCompany = (id) => {
  if (user.value.currentCompany?.id !== id) {
    getInfo(id);
  }
}

const openConfirmRemoveCompany = (id) => {
  confirm_remove_company.value.open(id);
}

const removeCompany = (id) => {
  api.request(`company/remove/${id}`).then(data => {
    getInfo();
    localStorage.setItem('currentCompanyId', '');
    confirm_remove_company.value.close();
  })
}

const getInfo = (id = null) => {
  if (id) localStorage.setItem('currentCompanyId', id);
  api.request(`user/get`, id ? {company: id} : {}).then((data) => {
    store.commit('setCities', data.response.cities);
    store.commit('setTerminals', data.response.terminals);
    if (data.response.user) {
      if (!data.response.user.currentCompany) {
        let ids = Object.keys(data.response.user.companies);
        if (ids.length) {
          data.response.user.currentCompany = data.response.user.companies[ids[0]];
          localStorage.setItem('currentCompanyId', ids[0]);
        }
      }
      store.commit('setUser', data.response.user);
    }
  });
}

onMounted(async()=>{
  await router.isReady();
})
const currentCityCode = computed(() => {
  return store.state.currentCityCode;
})
</script>

<style scoped lang="scss">
.profile-header-container {
  min-height: 0;
  padding-bottom: 0;
}
.profile-page-header {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-title {
    font-size: 16px;
    margin-right: 32px;
  }

  &-menu {
    display: flex;
    align-items: center;
    column-gap: 32px;

    a:hover {
      color: var(--text-gray-color);
    }
  }

  .profile-dropdown-menu {
    display: none;
  }
}

.company-list-item {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .profile-page-header {
    .profile-dropdown-menu {
      display: flex;
    }

    &-title {
      font-size: 18px;
    }

    &-menu {
      display: none;
    }
  }
}
</style>
